@import '@styles/components/Color.module.scss';

.cardContainer {
  background: white;
  border: 1px solid $neutral-300;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.cardTitle {
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
}

.cardDescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $neutral-600;
  margin: 0.5rem 0 0 0;
}

.paymentMethodsContainer {
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: auto auto;
  gap-x: 1rem;
}

.paymentMethod {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.625rem 1rem;
  cursor: pointer;

  img {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    flex-shrink: 0;
    margin-left: auto;
    &:dir(rtl) {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .checkbox {
    --checkbox-background-color: #{$green-400};
    --checkbox-size: 24px;
    border-radius: 2px;
    flex-shrink: 0;
  }
}
