@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';

.container {
  align-items: center;
  border-bottom: solid 1px $neutral-300;
  display: flex;
  justify-content: center;
  padding: 24px 32px;

  .hotelSearchForm {
    align-items: center;
    display: flex;
    gap: 16px 8px;
    height: 100%;
    max-width: 1280px;
    width: 100%;
    z-index: 3;

    .placePickerContainer {
      height: 64px;
      position: relative;
      width: 100%;

      .inputSelector {
        --input-selector-focus-width: 528px;
      }

      .category {
        color: $neutral-900;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0 24px;

        &.with8TopMargin {
          margin-top: 8px;
        }

        &.with16TopMargin {
          margin-top: 16px;
        }
      }

      .place {
        align-items: center;
        display: flex;
        gap: 0 8px;
        height: 48px;
        padding: 0 28px;

        .icon {
          @include search-form-icons;
          flex-shrink: 0;
        }

        .labelAndHotelCount {
          align-items: center;
          display: flex;
          gap: 0 8px;
          justify-content: space-between;
          width: calc(100% - 32px);

          .label {
            @include ellipsis;
            color: $neutral-900;
            font-size: 16px;
            line-height: 24px;
          }

          .hotelCount {
            color: $neutral-600;
            flex-shrink: 0;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
          }
        }

        &.with8TopMargin {
          margin-top: 8px;
        }

        &.selected,
        &.active,
        &:hover {
          background-color: $green-100;

          .icon {
            &.districtGrey {
              background-position: -25px -50px; // Is background-position of districtGreen
            }

            &.hotelGrey {
              background-position: -25px -75px; // Is background-position of hotelGreen
            }

            &.cityGrey {
              background-position: -50px -100px; // Is background-position of cityGreen
            }

            &.regionGrey {
              background-position: 0px -125px; // Is background-position of regionGreen
            }
          }

          .labelAndHotelCount {
            .label,
            .hotelCount {
              color: $green-500;
            }
          }
        }
      }

      .popularCities {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 24px 0;

        .popularCity {
          align-items: center;
          display: flex;
          height: 44px;
          padding: 0 8px;
          width: 25%;

          > div {
            @include ellipsis;
            color: $neutral-900;
            font-size: 16px;
            line-height: 24px;
          }

          &:hover {
            background-color: $green-100;

            > div {
              color: $green-500;
            }
          }
        }
      }
    }

    .datePickerContainer {
      flex-shrink: 0;
      height: 64px;
      position: relative;
      width: 500px;

      .hotelDateSelector {
        --hotel-date-selector-focus-width: 632px;
      }
    }

    .guestRoomContainer {
      flex-shrink: 0;
      height: 64px;
      position: relative;
      width: 256px;
    }

    .searchButton {
      align-items: center;
      background-color: $green-400;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 64px;
      justify-content: center;
      outline: none;
      width: 64px;

      .icon {
        fill: $neutral-white;
        height: 32px;
        width: 32px;
      }

      &:hover {
        background-color: $green-500;
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;
  }

  @media only screen and (max-width: 1279px) {
    .hotelSearchForm {
      .datePickerContainer {
        width: 416px;

        .hotelDateSelector {
          --hotel-date-selector-focus-width: 528px;
        }
      }

      .guestRoomContainer {
        width: 208px;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .hotelSearchForm {
      flex-wrap: wrap;

      .placePickerContainer {
        .inputSelector {
          --input-selector-focus-width: calc(100% + 32px);
        }
      }

      .datePickerContainer {
        width: 55%;

        .hotelDateSelector {
          --hotel-date-selector-focus-width: 576px;
        }
      }

      .guestRoomContainer {
        width: calc(45% - 8px - 8px - 64px);
      }
    }
  }
}

.fixed {
  position: fixed;
  background-color: $neutral-white;
  top: 0;
  width: 100%;
  z-index: 5;
}
