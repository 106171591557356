@import '@styles/mixins/Screen.module.scss';
@import '@styles/components/Color.module.scss';

.container {
  width: 100%;
  color: $neutral-900;

  button {
    background: none;
    outline: none;
    border: none;
    display: flex;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    .icon {
      height: 24px;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.navItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 1rem 1rem 1.5rem;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;

  > svg:last-child {
    margin-left: auto;
    display: none;
  }

  &:hover {
    background-color: $neutral-200;
  }

  &[data-open='false'] + ul {
    display: none;
  }

  &[data-active='true'] {
    color: $green-500;

    svg:last-child {
      display: block;

      &:dir(rtl) {
        transform: rotate(180deg);
        margin-right: auto;
        margin-left: 0;
      }
    }
  }

  &:dir(rtl) {
    padding: 1rem 1.5rem 1rem 1rem;
  }
}

.navSubItems {
  width: 100%;
  > li {
    .icon {
      height: 24px;
    }
  }
}

.navSubItem {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.625rem 1rem 0.625rem 4rem;
  cursor: pointer;
  border-radius: 4px;

  svg {
    margin-left: auto;
    display: none;
  }

  &:hover {
    background-color: $neutral-200;
  }

  &[data-active='true'] {
    color: $green-500;

    svg {
      display: block;

      &:dir(rtl) {
        transform: rotate(180deg);
        margin-right: auto;
        margin-left: 0;
      }
    }
  }

  &:dir(rtl) {
    padding: 0.625rem 4rem 0.625rem 1rem;
  }
}
