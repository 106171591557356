@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/Chevron.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  --flight-date-selector-focus-width: 150%;
  align-items: flex-end;
  background-color: $neutral-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: top right;
  transition: right 150ms ease-out, padding 150ms ease-out, top 150ms ease-out, width 150ms ease-out;
  width: 100%;

  &.fareCalendar {
    min-width: 100%;
    width: auto;

    .inputContainers {
      gap: pxToRem(94);
      justify-content: space-between;

      .clear {
        display: none;
      }
    }

    .dateInputsContainer {
      display: flex;
      height: 64px;
      justify-content: space-between;
      width: 100%;
    }

    &.focus {
      box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.4);
      padding: pxToRem(16) pxToRem(24);
      gap: pxToRem(16);
      width: auto;
      overflow: hidden;

      .directFlightToggleContainer {
        align-self: flex-start;
        display: flex;
        align-items: center;
        gap: pxToRem(8);
      }
    }

    .children {
      margin: 0;
    }
  }

  &.addReturnDateVariant {
    .chevron {
      border-color: $green-400 !important;
    }
  }

  .inputContainers {
    display: flex;
    height: 64px;
    justify-content: flex-end;
    width: 100%;

    .clear {
      background-color: $neutral-white;
      border-radius: 4px;
      border: none;
      color: $green-500;
      cursor: pointer;
      height: 48px;
      left: 16px;
      position: absolute;
      top: 16px;
      width: 70px;

      &:disabled {
        color: $neutral-400;

        &:hover {
          background: 0;
        }
      }

      &:hover {
        background: $neutral-200;
      }
    }

    .dateInputsContainer {
      display: flex;
      height: 64px;
      justify-content: flex-end;
      width: 100%;
    }

    .fromInputContainer,
    .toInputContainer {
      align-items: center;
      background-color: $neutral-white;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      padding-right: 10px;
      position: relative;
      width: 100%;

      &:dir(rtl) {
        padding-left: 10px;
        padding-right: 0;
      }

      > label {
        color: $neutral-600;
        font-size: 16px;
        left: 0;
        pointer-events: none;
        position: absolute;
        text-transform: capitalize;
        top: 0;
        transform-origin: top left;
        transform: translate(16px, 20px) scale(1);
        transition: transform 150ms ease-out;
      }

      > .input {
        @include ellipsis;
        background: none;
        border: none;
        color: $neutral-900;
        font-size: 16px;
        font-weight: 600;
        height: 100%;
        outline: none;
        padding: 30px 0 14px 16px;
        width: 100%;

        &:not(:empty) {
          & + label {
            transform: translate(16px, 12px) scale(0.75);
          }
        }
      }

      > button {
        align-items: center;
        background-color: $neutral-600;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        display: flex;
        height: 22px;
        justify-content: center;
        outline: none;
        position: absolute;
        right: 16px;
        width: 22px;

        .icon {
          fill: $neutral-white;
          flex-shrink: 0;
          height: 18px;
          width: 18px;
        }

        &:hover,
        &:focus {
          background-color: $neutral-black;
        }
      }

      &.addReturnDateContainer {
        background-color: $neutral-100;
        gap: 8px;
        padding: pxToRem(12);
        font-size: pxToRem(16);
        font-weight: 600;
        line-height: pxToRem(20);
        text-decoration: underline;
        text-underline-offset: pxToRem(3);
        text-decoration-thickness: 1px;
      }

      &:hover {
        border-color: $neutral-500;
      }

      &.focus {
        border-color: $green-400;
      }

      &.validated {
        &.error {
          border-color: $red-500;
        }
      }

      .chevronAndRemoveReturnContainer {
        align-items: center;
        display: flex;
        gap: 4px;
      }

      .leftAndRightChevronContainer {
        display: flex;

        &:dir(rtl) {
          flex-direction: row-reverse;
        }
      }

      .chevronContainer {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 20px;
        z-index: 1;

        .chevron {
          @include chevron(8px);
          border-color: $green-500;

          &.disabled {
            border-color: $neutral-400;
          }
        }

        &.hide {
          display: none;
        }
      }
    }

    .fromInputContainer {
      border-radius: 8px;
      border: solid 1px $neutral-300;
    }

    .toInputContainer {
      border-color: $neutral-300;
      border-radius: 0 8px 8px 0;
      border-style: solid;
      border-width: 1px 1px 1px 0;

      &.focus {
        border-width: 2px 2px 2px 0;
      }
    }

    .verticalLine {
      border-right: solid 1px $neutral-300;
    }

    &.isRange {
      .fromInputContainer {
        border-radius: 8px 0 0 8px;
        border-width: 1px 0 1px 1px;

        &.focus {
          border-width: 2px 0 2px 2px;
        }
      }
    }
  }

  &:hover {
    .inputContainers {
      .verticalLine {
        border-color: $neutral-500;
      }
    }
  }

  &.focus {
    border-radius: 16px;
    box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
    padding: 16px;
    right: -16px;
    top: -16px;
    width: var(--flight-date-selector-focus-width);
    z-index: 2;

    .inputContainers {
      .verticalLine {
        border-color: $green-400;
        border-width: 2px;
      }
    }
  }

  &.validated {
    &.error {
      .inputContainers {
        .verticalLine {
          border-color: $red-500;
        }
      }
    }
  }

  .children {
    display: none;
    margin: 8px 0 0;
    width: 100%;

    &.show {
      display: block;
    }
  }

  &:dir(rtl) {
    direction: rtl;
    left: 0;
    right: unset;
    transform-origin: top left;
    transition: left 150ms ease-out, padding 150ms ease-out, top 150ms ease-out,
      width 150ms ease-out;

    .inputContainers {
      .clear {
        right: 16px;
      }

      .fromInputContainer,
      .toInputContainer {
        > label {
          left: unset;
          right: 0;
          transform-origin: top right;
          transform: translate(-16px, 20px) scale(1);
        }

        > .input {
          padding: 30px 16px 14px 40px;

          &:not(:empty) {
            & + label {
              transform: translate(-16px, 12px) scale(0.75);
            }
          }
        }

        > button {
          left: 16px;
          right: unset;
        }
      }

      .toInputContainer {
        border-radius: 8px 0 0 8px;
        border-width: 1px 0 1px 1px;

        &.focus {
          border-width: 2px 0 2px 2px;
        }
      }

      &.isRange {
        .fromInputContainer {
          border-radius: 0 8px 8px 0;
          border-width: 1px 1px 1px 0;

          &.focus {
            border-width: 2px 2px 2px 0;
          }
        }
      }
    }

    &.focus {
      left: -16px;
      right: unset;
    }
  }
}
