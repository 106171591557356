@import '../../styles/components/Color.module';

.userIdContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;

  h6 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    color: #767676;
  }

  p {
    margin: 0;
  }
}

.successDescription {
  font-weight: 400;
  line-height: 24px;
}
