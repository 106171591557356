@import '@styles/components/Color.module.scss';
@import '@styles/mixins/Screen.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.header {
  width: 100%;

  &.sticky {
    position: fixed;
    z-index: 3;
  }

  &.isWhiteTheme {
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 20%);
  }

  &.notHome:not(.narrow) {
    --header-max-width: 1280px;
    border-bottom: 0.5px solid $neutral-300;

    &.flightSearchResultPage {
      --header-max-width: #{pxToRem(704)};

      @include md {
        --header-max-width: #{pxToRem(944)};
      }

      @include lg {
        --header-max-width: #{pxToRem(1136)};
      }
    }

    &.flightBookingPage {
      --header-max-width: #{pxToRem(1280)};
    }

    &.flightComparisonPage {
      --header-max-width: #{pxToRem(944)};
    }

    &.hotelBookingResultPage {
      --header-max-width: unset;
    }
  }
}

.footer {
  &.notHome:not(.narrow) {
    --footer-max-width: 1280px;
  }
}

.footerCheckoutPage {
  color: $neutral-600;
  font-size: 1rem;
  height: 4.5rem;
  line-height: 1.5rem;
  padding: 1.5rem;
  text-align: center;
}

.layoutPortalRoot {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}
