@import './Color.module.scss';

.container {
  align-items: center;
  background-color: $neutral-400;
  border-radius: 19.2px;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  position: relative;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 40px;

  .inner {
    background-color: $neutral-white;
    border-radius: 100%;
    height: 75%;
    min-width: 0;
    transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    translate: 3px 0;
    width: 45%;
  }

  &.checked {
    background-color: $green-400;

    .inner {
      transform: translateX(88%);
    }
  }

  &.isRtl {
    .inner {
      translate: -3px 0;
    }
  }

  &.checked.isRtl {
    .inner {
      transform: translateX(-88%);
    }
  }
}
