@mixin md {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}
