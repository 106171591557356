@mixin proposition-icons($size) {
  background-image: url('https://assets.wego.com/image/sprite/c_pad,q_auto:low,fl_lossy,w_200,h_200/v1581049436/propositions-icon');
  background-repeat: no-repeat;
  background-size: #{$size * 2} #{$size * 2}; // Should the number of proposition changed, update 2 and 2 accordingly
  height: $size;
  width: $size;

  &.lookNoFurther {
    background-position: 0px 0px;
  }

  &.shopWithConfidence {
    background-position: #{-$size} 0px;
  }

  &.payTheWayYouWant {
    background-position: 0px #{-$size};
  }

  &.instantBooking {
    background-position: #{-$size} #{-$size};
  }
}
