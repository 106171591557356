// Wego colors
$neutral-white: #ffffff;
$neutral-100: #fafafa;
$neutral-200: #f4f4f4;
$neutral-300: #dfdfdf;
$neutral-400: #bdbdbd;
$neutral-500: #9c9c9c;
$neutral-600: #767676;
$neutral-700: #565656;
$neutral-800: #323232;
$neutral-900: #1d1d1d;
$neutral-1000: #121212;
$neutral-black: #000000;

$green-100: #e7fddc;
$green-200: #bef7a1;
$green-300: #59cf1e;
$green-400: #44b50c;
$green-500: #188920;

$orange-100: #fff0e0;
$orange-200: #ffd4a8;
$orange-300: #ff9800;
$orange-400: #ff8000;
$orange-500: #b75c21;

$blue-100: #ebf5ff;
$blue-200: #c5e2ff;
$blue-300: #228df6;
$blue-400: #016cd5;

$red-100: #ffeeee;
$red-200: #ffcdcd;
$red-300: #fa4c4c;
$red-400: #df3131;
$red-500: #cf000f;

$yellow-100: #fdf5cb;
$yellow-200: #f6e177;
$yellow-300: #eccd33;

// Airbnb colors
$airbnb-red-color: #ff595e;

// Facebook colors
$facebook-blue-color: #4267b2;

// TrustYou colors
$trust-you-green-color: #7cb342;
$trust-you-yellow-color: #ffa726;
$trust-you-red-color: #f06748;
$trust-you-blue-color: #3498db;
$trust-you-dark-blue-color: #2a5caa;
$trust-you-yellow-background: #fff6e9;
$trust-you-red-background: #fdefec;

// ShopCash colors
$shopcash-indigo-color: #2a2961;
$shopcash-lavender-color: #f4f4ff;
$shopcash-grey-color: #767676;

// Tabby color
$tabby-blue: #29c1a7;

// Promo Code
$light-text-warning: #d85d0d;

// old color mapping
// --dark-orange-color = $orange-400
// --medium-grey-color = $neutral-600
// --grey-color = $neutral-400
// --dark-color = $neutral-900
// --dark-blue-color = $blue-300
// --black-grey-color = $neutral-700
// --light-grey-color = $neutral-300
// --dark-green-color = $green-400
// --subtle-grey-color = $neutral-200

// Old Color codes
$bow-btn-color: #21a024;
$bow-btn-box-shadow-color: #007f03;
$bow-btn-hover-color: #2cc330;
$view-deal-btn-color: #f2831b;
$view-deal-btn-box-shadow-color: #d97517;
$view-deal-btn-hover-color: #fea400;
$search-btn-hover-color: #de7000;
$light-grey-color: #e5e3e8;
