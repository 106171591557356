@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  height: 280px;
  margin: 8px;
  overflow: hidden;
  text-decoration: none;
  width: 272px;

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: calc(100% - 176px);
    padding: 16px;

    .title {
      @include multi-line-ellipsis(2);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .propertiesCount {
      color: $neutral-600;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 4px;
    }
  }

  .image {
    height: 176px;
    width: 100%;
  }

  &:dir(rtl) {
    direction: rtl;
  }
}
