@import '@styles/components/Color.module.scss';

.container {
  position: relative;
  width: var(--checkbox-size, 1.25rem);
  height: var(--checkbox-size, 1.25rem);
  background-color: $neutral-white;
  border: 0.125rem solid $neutral-500;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0.0625rem;
  border-radius: 50%;
  direction: ltr;

  &.checked {
    background-color: var(--checkbox-background-color, $orange-300);
    border-color: var(--checkbox-background-color, $orange-300);

    &::after {
      transform: rotateZ(45deg) scale(1);
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 35%;
    height: 70%;
    border-style: none solid solid none;
    border-width: 0 0.125rem 0.125rem 0;
    border-color: var(--checkbox-tick-color, $neutral-white);
    transform: rotateZ(45deg) scale(0);
    transform-origin: 90% 95%;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
