@import '@styles/components/Color.module.scss';
@import '@styles/mixins/Screen.module.scss';

.container {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;

  left: 0;
  &:dir(rtl) {
    right: 0;
    left: auto;
  }

  &[data-open='true'] {
    .drawer {
      transform: translateX(100%);
      &:dir(rtl) {
        transform: translateX(-100%);
      }
    }

    .backdrop {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: $neutral-black, $alpha: 0.5);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.drawer {
  position: absolute;
  top: 0;
  width: 24.5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;

  right: 0;
  &:dir(rtl) {
    left: 0;
    right: auto;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.drawerHeader {
  background-color: $neutral-white;
  border-bottom: 1px solid $neutral-300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  color: $neutral-900;
  flex-shrink: 0;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  button {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.drawerBody {
  padding: 1.5rem;
  background-color: $neutral-100;
  overflow-y: auto;
  flex-grow: 1;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
    display: none;
  }

  100% {
    transform: translateX(0%);
  }
}
