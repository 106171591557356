@import '@styles/components/Color.module.scss';
@import '@styles/mixins/Screen.module.scss';

.dropdown {
  position: absolute;
  right: 0;
  top: 130%;
  width: 100%;
  max-width: 15rem;

  &:dir(rtl) {
    right: auto;
    left: 0;
  }
}

.dropdownContainer {
  background-color: $neutral-white;
  box-shadow: 0px 2px 8px 0px rgba($neutral-black, 0.08);
  padding: 0.75rem 0px;
  border-radius: 16px;
  overflow: hidden;
}

.triangle {
  background-color: $neutral-white;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 20%;
  rotate: 45deg;
  top: -6px;
  clip-path: inset(-10px 0px 0px -10px);
  display: inline-block;
  box-shadow: 0px 2px 8px 0px rgba($neutral-black, 0.08);

  &:dir(rtl) {
    left: 20%;
    right: inherit;
  }

  @include md {
    right: 40%;

    &:dir(rtl) {
      right: 60%;
    }
  }
}

.dropdownItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.dropdownItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  gap: 1rem;
  height: 3.5rem;
  line-height: 24px;
  padding: 0 1rem;
  text-transform: capitalize;
  text-decoration: none;
  color: $neutral-900;
  background-color: transparent;
  border: 0;

  &:hover {
    background-color: $neutral-200;
  }

  &[data-active='true'] {
    background-color: $neutral-200;

    .linkText {
      font-weight: 500;
    }

    .linkIcon {
      color: $neutral-900;
    }
  }
}

.linkIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: $neutral-600;
}

.linkText {
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: $neutral-900;
}
