@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/Triangle.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .inputContainer {
    align-items: center;
    background-color: $neutral-white;
    border-radius: 8px;
    border: solid 1px $neutral-300;
    cursor: pointer;
    display: flex;
    height: 64px;
    overflow: hidden;
    position: relative;

    > label {
      color: $neutral-600;
      font-size: 16px;
      left: 0;
      pointer-events: none;
      position: absolute;
      text-transform: capitalize;
      top: 0;
      transform-origin: top left;
      transform: translate(16px, 20px) scale(1);
      transition: transform 150ms ease-out;
    }

    > .input {
      @include ellipsis;
      background: none;
      border: none;
      color: $neutral-900;
      font-size: 16px;
      height: 100%;
      outline: none;
      padding: 30px 40px 14px 16px;
      width: 100%;
      font-weight: 600;

      &:not(:empty) {
        & + label {
          transform: translate(16px, 12px) scale(0.75);
        }
      }
    }

    .triangle {
      @include triangle(6px);
      color: $neutral-400;
      position: absolute;
      right: 16px;
    }

    &:hover {
      border-color: $neutral-500;
    }
  }

  .children {
    background-color: $neutral-white;
    border-radius: 16px;
    box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: -16px;
    top: -16px;
    transform-origin: top right;
    transform: scale(0);
    transition: opacity 100ms ease-out, transform 150ms ease-out;
    width: 506px;
  }

  &.focus {
    z-index: 2;

    .inputContainer {
      border-color: $green-400;
    }

    .children {
      opacity: 1;
      transform: scale(1);
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .inputContainer {
      > label {
        left: unset;
        right: 0;
        transform-origin: top right;
        transform: translate(-16px, 20px) scale(1);
      }

      > .input {
        padding: 30px 16px 14px 40px;

        &:not(:empty) {
          & + label {
            transform: translate(-16px, 12px) scale(0.75);
          }
        }
      }

      .triangle {
        left: 16px;
        right: unset;
      }
    }

    .children {
      left: -16px;
      right: unset;
      transform-origin: top left;
    }
  }
}
