@import './Color.module.scss';
@import './NewInputStyle.module.scss';
@import './OAuthProviderLogo.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 4px;
  box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
  color: $neutral-900;
  overflow: hidden;
  width: 392px;

  .header {
    align-items: center;
    background-color: $neutral-200;
    border-bottom: 1px solid $neutral-300;
    display: flex;
    flex-direction: column;
    height: 96px;
    padding: 10px;

    .icon {
      align-self: flex-end;
      cursor: pointer;
      fill: $neutral-600;
      flex-shrink: 0;
      height: 18px;
      width: 18px;
    }

    .title {
      font-size: 20px;
      line-height: 30px;
    }

    .subtitle {
      color: $neutral-600;
      font-size: 14px;
      line-height: 21px;
      margin-top: 4px;

      > span {
        color: $green-400;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;

    .oAuthProvider {
      align-items: center;
      border-radius: 30px;
      border: none;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      height: 44px;
      outline: none;
      padding: 0 12px;

      &.facebook {
        background-color: $facebook-blue-color;
        color: $neutral-white;
      }

      &.google {
        background-color: $neutral-white;
        color: $neutral-600;
        padding: 0;
        box-shadow: none;
      }

      .oAuthProviderLogo {
        @include o-auth-provider-logo;
        margin-right: 24px;
      }

      & + .oAuthProvider {
        margin-top: 8px;
      }
    }

    .strikethrough {
      border-bottom: 1px solid $neutral-300;
      height: 12px;
      margin: 16px 0 20px 0;
      text-align: center;

      &::after {
        background-color: $neutral-white;
        content: attr(data-text);
        font-size: 14px;
        margin-bottom: 20px;
        padding: 0 6px;
      }
    }

    .loginErrorMessage {
      background-color: $red-100;
      border-radius: 4px;
      color: $red-300;
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 16px;
      padding: 12px 16px;
    }

    .input {
      @include new-input-style;
    }

    .link {
      align-self: flex-start;
      color: $green-400;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;

      & + .link {
        margin-top: 16px;
      }
    }

    .login {
      align-items: center;
      background-color: $green-400;
      border-radius: 4px;
      border: none;
      color: $neutral-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      justify-content: center;
      margin-top: 48px;
      outline: none;
      padding: 0;
      text-transform: capitalize;
      width: 100%;

      .spinner {
        --spinner-color: #{$neutral-100};
        --spinner-background-color: #{$neutral-300};
      }

      &:hover {
        background-color: $green-500;
      }

      &:disabled {
        background-color: $neutral-400;
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .content {
      .oAuthProvider {
        .oAuthProviderLogo {
          margin-left: 24px;
          margin-right: 0;
        }
      }
    }
  }
}
