@import '@styles/components/Chevron.module.scss';
@import '@styles/components/Color.module.scss';
@import '@styles/components/PropositionIcons.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;

  .heroImageContainer {
    border-radius: 0 0 100% 100%;
    height: 544px;
    margin: 0 -20%;
    overflow: hidden;
    padding: 0 20%;
    overflow: hidden;
    position: relative;

    .heroImage {
      background-position: center 30%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 0 32px;
      width: 100%;

      .tagline {
        color: $neutral-white;
        font-size: 36px;
        font-weight: 600;
        left: 0;
        line-height: 1.67;
        position: absolute;
        right: 0;
        text-align: center;
        top: 180px;

        &.withTextShadow {
          text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
        }
      }

      .campaignLogoImageContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 17rem;
        max-width: 1136px;
        width: 100%;

        > img {
          height: 100px;
          width: 100px;
        }
      }

      .carouselNavigation {
        display: flex;
        height: 18px;
        position: absolute;
        top: 21rem;
        left: 50%;
        z-index: 1;

        .navigationItem {
          border-radius: 50%;
          border: 2px solid $neutral-white;
          cursor: pointer;
          height: 12px;
          margin: 3px;
          width: 12px;

          &:hover {
            background-color: $neutral-white;
            box-shadow: 0 0 8px $neutral-white;
          }

          &.active {
            background-color: $neutral-white;
          }
        }
      }
    }

    .heroImageCampaign {
      height: 100%;
      width: 100%;
      position: sticky;
      top: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 1000ms ease-in-out;

      background-position: center 30%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
    }

    .activeHeroImageCampaign {
      opacity: 1;
    }
  }

  .heroImageContainerNew {
    height: pxToRem(472px);
    border-radius: 0;
    margin: 0;
    padding: 0;
  }

  .contentContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: -184px;
    padding: 0 32px 48px;

    .egyptCentralBankNotice,
    .section,
    .propositions {
      max-width: 1136px;
      width: 100%;
    }

    .egyptCentralBankNotice {
      background-color: $yellow-100;
      border-radius: 8px;
      color: $neutral-900;
      font-size: 14px;
      line-height: 20px;
      margin-top: 24px;
      padding: 12px 16px;
    }

    .section {
      margin-top: 48px;

      .sectionTitle {
        align-items: center;
        display: flex;
        font-size: 24px;
        font-weight: 600;
        height: 28px;
        justify-content: space-between;
        line-height: 28px;

        > a {
          align-items: center;
          color: $green-400;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          gap: 0 4px;
          line-height: 24px;
          text-decoration: none;
        }
      }

      .marketingPartnerPromos {
        margin: 8px -8px 0px;
      }

      .marketingPartnerPromo {
        background-color: $neutral-white;
        border-radius: 8px;
        box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
        color: $neutral-900;
        display: flex;
        flex-direction: column;
        height: 184px;
        margin: 8px;
        overflow: hidden;
        text-decoration: none;
        width: 368px;

        > img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .tripIdeas {
        margin-top: 16px;
      }

      .seoTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      > nav {
        display: flex;
        flex-wrap: wrap;
        gap: 8px 16px;
        margin-top: 8px;

        > a {
          color: $neutral-900;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          width: calc(25% - 16px);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .inspirationalShoppingBanner {
      border-radius: 8px;
      cursor: pointer;
      height: 160px;
      margin-top: 48px;
      max-width: 1136px;
      width: 100%;
    }
  }

  .marginTop {
    margin-top: pxToRem(-376px);
  }

  @media only screen and (max-width: 1279px) {
    .heroImageContainer {
      height: 390px;

      .heroImage {
        .tagline {
          top: 144px;
        }

        .campaignLogoImageContainer {
          margin-top: 13rem;
        }

        .carouselNavigation {
          top: 15rem;
        }
      }
    }

    .heroImageContainerNew {
      height: pxToRem(472px);
    }

    .contentContainer {
      margin-top: -130px;
    }

    .marginTop {
      margin-top: pxToRem(-376px);
    }
  }

  @media only screen and (max-width: 1023px) {
    .heroImageContainerNew {
      height: pxToRem(570px);
    }

    .contentContainer {
      .section {
        > nav {
          > a {
            width: calc(33% - 16px);
          }
        }
      }

      .installBanner {
        .content {
          padding: 36px;

          .appsContainer {
            .qrCode {
              height: 80px;
              width: 80px;
            }
          }
        }
      }
    }

    .marginTop {
      margin-top: pxToRem(-478px);
    }
  }

  @media only screen and (max-width: 768px) {
    .heroImageContainer {
      .heroImage {
        .campaignLogoImageContainer {
          margin-top: 13rem;

          > img {
            height: 60px;
            width: 60px;
          }
        }

        .carouselNavigation {
          top: 15rem;
        }
      }
    }
  }
}
