@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';

.container {
  --hotel-date-selector-focus-width: 150%;
  background-color: $neutral-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform-origin: top left;
  transition: left 150ms ease-out, padding 150ms ease-out, top 150ms ease-out, width 150ms ease-out;
  width: 100%;

  .inputContainers {
    display: flex;
    height: 64px;
    width: 100%;

    .fromInputContainer,
    .toInputContainer {
      align-items: center;
      background-color: $neutral-white;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      position: relative;
      width: 100%;

      > label {
        color: $neutral-600;
        font-size: 16px;
        left: 0;
        pointer-events: none;
        position: absolute;
        text-transform: capitalize;
        top: 0;
        transform-origin: top left;
        transform: translate(16px, 20px) scale(1);
        transition: transform 150ms ease-out;
      }

      > .input {
        @include ellipsis;
        background: none;
        border: none;
        color: $neutral-900;
        font-size: 16px;
        height: 100%;
        outline: none;
        padding: 30px 40px 14px 16px;
        width: 100%;
        font-weight: 600;

        &:not(:empty) {
          & + label {
            transform: translate(16px, 12px) scale(0.75);
          }
        }
      }

      > button {
        align-items: center;
        background-color: $neutral-600;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        display: flex;
        height: 22px;
        justify-content: center;
        outline: none;
        position: absolute;
        right: 16px;
        width: 22px;

        .icon {
          fill: $neutral-white;
          flex-shrink: 0;
          height: 18px;
          width: 18px;
        }

        &:hover,
        &:focus {
          background-color: $neutral-black;
        }
      }

      &:hover {
        border-color: $neutral-500;
      }

      &.focus {
        border-color: $green-400;
      }

      &.validated {
        &.error {
          border-color: $red-500;
        }
      }
    }

    .fromInputContainer {
      border-radius: 8px;
      border: solid 1px $neutral-300;
    }

    .toInputContainer {
      border-color: $neutral-300;
      border-radius: 0 8px 8px 0;
      border-style: solid;
      border-width: 1px 1px 1px 0;
    }

    .verticalLine {
      border-right: solid 1px $neutral-300;
    }

    &.isRange {
      .fromInputContainer {
        border-radius: 8px 0 0 8px;
        border-width: 1px 0 1px 1px;
      }
    }
  }

  &:hover {
    .inputContainers {
      .verticalLine {
        border-color: $neutral-500;
      }
    }
  }

  &.focus {
    border-radius: 16px;
    box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
    left: -16px;
    padding: 16px;
    top: -16px;
    width: var(--hotel-date-selector-focus-width);
    z-index: 2;

    .inputContainers {
      .verticalLine {
        border-color: $green-400;
      }
    }
  }

  &.validated {
    &.error {
      .inputContainers {
        .verticalLine {
          border-color: $red-500;
        }
      }
    }
  }

  .children {
    display: none;
    margin: 8px 0 0;

    &.show {
      display: block;
    }
  }

  &:dir(rtl) {
    direction: rtl;
    left: unset;
    right: 0;
    transform-origin: top right;
    transition: right 150ms ease-out, padding 150ms ease-out, top 150ms ease-out,
      width 150ms ease-out;

    .inputContainers {
      .fromInputContainer,
      .toInputContainer {
        > label {
          left: unset;
          right: 0;
          transform-origin: top right;
          transform: translate(-16px, 20px) scale(1);
        }

        > .input {
          padding: 30px 16px 14px 40px;

          &:not(:empty) {
            & + label {
              transform: translate(-16px, 12px) scale(0.75);
            }
          }
        }

        > button {
          left: 16px;
          right: unset;
        }
      }

      .toInputContainer {
        border-radius: 8px 0 0 8px;
        border-width: 1px 0 1px 1px;
      }

      &.isRange {
        .fromInputContainer {
          border-radius: 0 8px 8px 0;
          border-width: 1px 1px 1px 0;
        }
      }
    }

    &.focus {
      left: unset;
      right: -16px;
    }
  }
}
