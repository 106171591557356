@import './Color.module.scss';

.container {
  position: relative;

  img {
    border-radius: inherit;
    height: 100%;
    object-fit: var(--image-object-fit, cover);
    position: absolute;
    transition: opacity 500ms ease;
    width: 100%;

    &.greyBackground {
      background-color: $neutral-400;
    }

    &.hide {
      opacity: 0;
    }
  }
}
