@import '@styles/components/Color.module.scss';

.container {
  align-items: center;
  background-color: $neutral-900;
  color: $neutral-white;
  display: flex;
  flex-direction: column;
  padding: 48px 32px;

  .links {
    display: flex;
    max-width: var(--footer-max-width, 1136px);
    width: 100%;

    .category {
      flex-shrink: 0;
      width: 25%;

      .categoryHeader {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
      }

      a {
        color: $neutral-white;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
        text-decoration: none;
      }
      .wegoPro {
        align-items: center;
        column-gap: 0.5rem;
        display: flex;
        .badgeNew {
          background-color: $orange-400;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 600;
          line-height: 1.25rem;
          padding: 0 0.375rem;
          text-transform: uppercase;
          width: 2.5rem;
        }
      }
    }
  }

  .brandAndSocialMediaLinks {
    align-items: center;
    border-top: 1px solid $neutral-700;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 24px;
    margin-top: 32px;
    max-width: var(--footer-max-width, 1136px);
    padding: 16px 0;
    width: 100%;

    .socialMediaLinks {
      display: flex;

      .socialMedia {
        background-image: url('https://assets.wego.com/image/sprite/c_pad,q_auto,f_auto,fl_lossy,w_48/v1700199703/footer_logos');
        background-repeat: no-repeat;
        background-size: 24px 144px;
        display: block;
        height: 24px;
        text-decoration: none;
        width: 24px;

        &.facebook {
          background-position-y: -24px;
        }

        &.twitter {
          background-position: 0px 0px;
        }

        &.instagram {
          background-position-y: -48px;
        }

        &.linkedin {
          background-position-y: -72px;
        }

        &.snapchat {
          background-position-y: -96px;
        }

        &.youtube {
          background-position-y: -120px;
        }

        & + .socialMedia {
          margin-left: 4px;
        }
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .brandAndSocialMediaLinks {
      .socialMediaLinks {
        .socialMedia {
          & + .socialMedia {
            margin-left: 0;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
