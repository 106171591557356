@import '@styles/components/Color.module.scss';
@import '@styles/mixins/Screen.module.scss';

.container {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $neutral-100;

  @include md {
    padding: 2rem 2.5rem;
  }
}

.layout {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 71rem;

  @include lg {
    gap: 2.5rem;
  }
}

.sidebar {
  width: 100%;
  max-width: 19rem;
  display: none;
  color: $neutral-900;

  @include md {
    display: block;
  }

  @include lg {
    max-width: 21.5rem;
  }
}

.content {
  flex-grow: 1;
}

.hamburgerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  border: 2px solid $neutral-300;
  border-radius: 9999px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  gap: 0.25rem;
  height: 2.75rem;
  margin-bottom: 1.5rem;

  svg {
    transform: scale(0.85);
  }

  @include md {
    display: none;
  }
}
