@import '@styles/components/Chevron.module.scss';
@import '@styles/components/Color.module.scss';
@import '@styles/components/PropositionIcons.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';

.installBanner {
  max-width: 1136px;
  width: 100%;
  display: flex;
  margin-top: 48px;
}

.imageContainer {
  background-image: url('https://zen.wego.com/cdn-cgi/image/width=536/web/illustrations/download-app-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 360px;
  overflow: hidden;
  position: relative;
  width: 536px;
}

.image {
  height: 664px;
  left: 50%;
  position: absolute;
  top: 24px;
  transform: translateX(-50%);
  width: 300px;
}

.content {
  background-color: $neutral-100;
  max-width: 600px;
  padding: 48px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

.messageContainer {
  align-items: flex-start;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  gap: 0 12px;
  line-height: 24px;
  margin-top: 24px;

  & + .messageContainer {
    margin-top: 16px;
  }
}

.tickContainer {
  align-items: center;
  background-color: $green-400;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  width: 24px;

  > .icon {
    fill: $neutral-white;
    height: 18px;
    width: 18px;
  }
}

.scanToDownload {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
}

.appsContainer {
  display: flex;
  align-items: center;
  gap: 0 24px;
  margin-top: 16px;
}

.qrCode {
  height: 140px;
  width: 140px;
}

.apps {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > a {
    text-decoration: none;
    height: 40px;
  }

  img {
    object-fit: contain;
    height: 40px;
    position: relative;
    width: fit-content;
  }
}
