@mixin triangle($size) {
  width: 0;
  height: 0;

  &.up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;

    border-bottom: $size solid currentColor;
  }

  &.down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;

    border-top: $size solid currentColor;
  }

  &.right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;

    border-left: $size solid currentColor;
  }

  &.left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;

    border-right: $size solid currentColor;
  }
}
