@import '@styles/components/Color.module.scss';

.container {
  display: none;
  border-style: solid;
  border-radius: 50%;
  animation: rotating 1s infinite linear;

  width: var(--spinner-size, 1rem);
  height: var(--spinner-size, 1rem);

  border-top-color: var(--spinner-background-color, $neutral-white);
  border-right-color: var(--spinner-background-color, $neutral-white);
  border-bottom-color: var(--spinner-background-color, $neutral-white);
  border-left-color: var(--spinner-color, $green-300);

  &.inverted {
    border-width: var(--spinner-thickness, 0.125rem);
    border-top-color: var(--spinner-background-color, $green-300);
    border-right-color: var(--spinner-background-color, $green-300);
    border-bottom-color: var(--spinner-background-color, $green-300);
    border-left-color: var(--spinner-color, $neutral-white);
  }

  &.orange {
    border-width: var(--spinner-thickness, 0.125rem);
    border-top-color: var(--spinner-background-color, $neutral-white);
    border-right-color: var(--spinner-background-color, $neutral-white);
    border-bottom-color: var(--spinner-background-color, $neutral-white);
    border-left-color: var(--spinner-color, $orange-300);

    &.inverted {
      border-width: var(--spinner-thickness, 0.125rem);
      border-top-color: var(--spinner-background-color, $orange-300);
      border-right-color: var(--spinner-background-color, $orange-300);
      border-bottom-color: var(--spinner-background-color, $orange-300);
      border-left-color: var(--spinner-color, $neutral-white);
    }
  }

  &.red {
    border-width: var(--spinner-thickness, 0.125rem);
    border-top-color: var(--spinner-background-color, $neutral-white);
    border-right-color: var(--spinner-background-color, $neutral-white);
    border-bottom-color: var(--spinner-background-color, $neutral-white);
    border-left-color: var(--spinner-color, $red-400);

    &.inverted {
      border-width: var(--spinner-thickness, 0.125rem);
      border-top-color: var(--spinner-background-color, $red-400);
      border-right-color: var(--spinner-background-color, $red-400);
      border-bottom-color: var(--spinner-background-color, $red-400);
      border-left-color: var(--spinner-color, $neutral-white);
    }
  }

  &.grey {
    border-width: var(--spinner-thickness, 0.125rem);
    border-top-color: var(--spinner-background-color, $neutral-white);
    border-right-color: var(--spinner-background-color, $neutral-white);
    border-bottom-color: var(--spinner-background-color, $neutral-white);
    border-left-color: var(--spinner-color, $neutral-300);

    &.inverted {
      border-width: var(--spinner-thickness, 0.125rem);
      border-top-color: var(--spinner-background-color, $neutral-300);
      border-right-color: var(--spinner-background-color, $neutral-300);
      border-bottom-color: var(--spinner-background-color, $neutral-300);
      border-left-color: var(--spinner-color, $neutral-white);
    }
  }
}

.loading {
  display: block;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
