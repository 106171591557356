@import '@styles/components/Chevron.module.scss';
@import '@styles/components/Color.module.scss';
@import '@styles/components/PropositionIcons.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.searchForm {
  max-width: pxToRem(1136px);
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.formMinHeight {
  min-height: pxToRem(376px);

  @media only screen and (max-width: 1023px) {
    min-height: pxToRem(478px);
  }
}

.productIcons {
  @include search-form-icons;
}

.productTabs {
  border-radius: 8px 8px 0 0;
  display: flex;
  height: 56px;
  overflow: hidden;
}

.tabs {
  display: flex;
  gap: 2px;
}

.tab {
  align-items: center;
  color: $neutral-white;
  display: flex;
  font-size: 1rem;
  gap: 0 8px;
  justify-content: center;
  line-height: 1.6;
  padding: 0 2rem;
  text-decoration: none;
  backdrop-filter: blur(8px);
  background-color: rgba($color: $neutral-black, $alpha: 0.24);

  &.active {
    background-color: $neutral-white;
    color: $neutral-900;
  }
}

.wegoProTab {
  border-radius: 8px 8px 0 0;
  gap: 0 5px;
  padding: 0 1rem;
  position: relative;

  svg {
    height: 30px;
    width: 30px;
  }
}

.badgeNew {
  background-color: $orange-400;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  height: 1.25rem;
  padding: 0 0.375rem;
  position: absolute;
  text-transform: uppercase;
  top: -10px;
  width: 2.5rem;

  left: 1rem;
  &:dir(rtl) {
    left: auto;
    right: 1rem;
  }
}

.tabContent {
  background-color: $neutral-white;
  box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.2);

  border-radius: 0 0.5rem 0.5rem 0.5rem;
  &:dir(rtl) {
    border-radius: 0.5rem 0 0.5rem 0.5rem;
  }
}
