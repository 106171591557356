@import '../../styles/components/Color.module.scss';
@import '../../styles/components/CountryFlags.module.scss';
@import '../../styles/components/Ellipsis.module.scss';
@import '../../styles/components/Triangle.module.scss';

.countryFlags {
  @include country-flags;
  flex-shrink: 0;
}

.container {
  display: flex;
  gap: 12px;

  .currentSite,
  .selectedLocale,
  .currency,
  .allPickers {
    align-items: center;
    background-clip: padding-box;
    background-color: transparent;
    border-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 32px;
    outline: none;
    position: relative;
    padding: 0px;

    &.expanded {
      .triangle {
        transform: scaleY(-1);
      }

      &::after {
        border-bottom: 6px solid $neutral-white;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        bottom: -6px;
        content: '';
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(2px);
        z-index: 1;
      }
    }

    .triangle {
      @include triangle(6px);
      color: $neutral-white;
      margin-left: 12px;
    }
  }

  .allPickers {
    border-radius: 100px;
    border-width: 1px;
  }

  .currentSite {
    border-radius: 100px 0 0 100px;
    border-width: 1px 0 1px 1px;
  }

  .selectedLocale {
    border-width: 1px;

    .localeCode {
      color: $neutral-white;
      font-size: 16px;
      line-height: 1.5;
      text-decoration: none;
      text-transform: uppercase;
    }

    .separator {
      align-items: center;
      color: $neutral-white;
      display: flex;
      font-size: 15px;
      height: 100%;
      line-height: 1.6;
      margin: 0 5px;
    }
  }

  .currency {
    border-radius: 0 100px 100px 0;
    border-width: 1px 1px 1px 0;
    color: $neutral-white;
    font-size: 16px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  .dropdown {
    align-items: flex-start;
    background-color: $neutral-white;
    border-radius: 16px;
    box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
    cursor: default;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateY(6px);
    // related to header z-index change
    z-index: 1;

    &.all {
      overflow-y: auto;
      padding: 24px;
      width: 690px;
    }

    &.countries,
    &.currencies {
      padding: 24px 0 0 24px;
      width: 778px;
    }

    &.locales {
      padding: 24px 24px 0 24px;
      width: 588px;
    }

    .label {
      color: $neutral-900;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.6;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      overflow-y: auto;
      padding-bottom: 24px;

      &.allContent {
        overflow-y: unset;
        padding-bottom: 0;
        width: 100%;

        & > * {
          flex-basis: 33.3%;
        }
      }

      .item {
        align-items: center;
        color: $neutral-900;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        gap: 12px;
        height: 44px;
        line-height: 1.6;
        padding: 0 12px;
        text-decoration: none;
        width: 180px;

        &.active {
          color: $green-400;

          .name {
            font-weight: 500;
          }
        }

        &:hover {
          background-color: $neutral-200;
        }

        .code {
          font-weight: 700;
          text-transform: uppercase;
        }

        .name {
          @include ellipsis;
        }
      }
    }

    .compactSection {
      width: 100%;

      &:not(:last-of-type) {
        border-bottom: 1px solid $neutral-300;
        margin-bottom: 24px;
      }
    }

    .compactToggle {
      color: $orange-400;
      cursor: pointer;
      font-size: 14px;
      padding-block: 8px;
    }
  }

  &.whiteBackground {
    background-color: $neutral-white;

    .currentSite,
    .selectedLocale,
    .currency,
    .allPickers {
      background-color: $neutral-white;
      border-color: $neutral-300;
      color: $neutral-900;

      .triangle {
        color: $neutral-600;
      }
    }

    .selectedLocale {
      .localeCode {
        color: $neutral-900;
      }

      .separator {
        color: $neutral-900;
      }
    }
    .separator {
      color: $neutral-900;
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .currentSite,
    .selectedLocale,
    .currency,
    .allPickers {
      &.expanded {
        &::after {
          left: unset;
          right: 50%;
          transform: translateX(50%) translateY(2px);
        }
      }

      .triangle {
        margin-left: 0;
        margin-right: 12px;
      }
    }

    .currentSite {
      border-radius: 0 100px 100px 0;
      border-width: 1px 1px 1px 0;
    }

    .currency {
      border-radius: 100px 0 0 100px;
      border-width: 1px 0 1px 1px;
    }

    .dropdown {
      left: 0;
      right: unset;
      transform: translateY(7px);

      &.countries,
      &.currencies {
        padding: 24px 24px 0 0;
      }
    }
  }

  .separator {
    display: flex;
    align-items: center;
    height: 100%;
    color: $neutral-white;
    font-size: 16px;
    line-height: 1;
    user-select: none;
    font-weight: 400;
  }
  .fw600 {
    font-weight: 600;
  }
}
