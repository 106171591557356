@import '@styles/components/Color.module.scss';
@import '@styles/components/Triangle.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  display: flex;
  gap: 0 8px;

  &.flightSearch2 {
    .buttonFilter {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
    }
  }

  .buttonFilter {
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    gap: 0 8px;
    height: 48px;
    line-height: 24px;
    padding: 0 12px;
    position: relative;
    text-transform: capitalize;
    white-space: nowrap;

    &:hover {
      background-color: $neutral-200;
    }

    &.expanded {
      background-color: transparent;
      cursor: default;

      .passengerPickerDropdown,
      .cabinClassPickerDropdown,
      .paymentTypePickerDropdown {
        opacity: 1;
        transform: scale(1);
      }
    }

    .triangle {
      @include triangle(6px);
      color: $neutral-900;
      flex-shrink: 0;
      margin: 0 6px;
    }

    .passengerPickerDropdown {
      background-color: $neutral-white;
      border-radius: 16px;
      box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
      display: flex;
      flex-direction: column;
      opacity: 0;
      overflow: hidden;
      padding: 24px 16px 18px;
      position: absolute;
      top: 0;
      transform: scale(0);
      transition: opacity 100ms ease-out, transform 150ms ease-out;
      z-index: 2;

      .minusSign {
        &::before {
          content: '\FF0D';
        }
      }

      .plusSign {
        &::before {
          content: '\FF0B';
        }
      }

      .passenger {
        align-items: center;
        display: flex;
        gap: 0 32px;
        justify-content: space-between;
        padding: 8px 0;

        .type {
          align-items: center;
          display: flex;
          gap: 0 8px;

          .icon {
            @include search-form-icons;
            flex-shrink: 0;
          }

          .label {
            color: $neutral-900;
            font-size: 16px;
            line-height: 24px;
          }

          .requirement {
            color: $neutral-600;
            font-size: 12px;
            line-height: 18px;
            white-space: nowrap;
          }
        }

        .stepperContainer {
          align-items: center;
          display: flex;
          gap: 0 8px;

          > button {
            background-color: $green-400;
            border-radius: 4px;
            border: none;
            color: $neutral-white;
            cursor: pointer;
            height: 22px;
            outline: none;
            padding: 0;
            width: 22px;

            &.minusSign,
            &.plusSign {
              &::before {
                align-items: center;
                color: $neutral-white;
                display: flex;
                font-size: 16px;
                font-weight: 600;
                height: 22px;
                justify-content: center;
                width: 22px;
              }
            }

            &:hover {
              background-color: $green-500;
            }

            &:disabled {
              background-color: $neutral-400;
            }
          }

          .counter {
            align-items: center;
            color: $neutral-900;
            display: flex;
            font-size: 16px;
            justify-content: center;
            line-height: 24px;
            width: 24px;
          }
        }
      }

      .applyContainer {
        border-top: 1px solid $neutral-300;
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        padding-top: 10px;

        > button {
          background-color: transparent;
          border: none;
          color: $green-400;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
          outline: none;
          padding: 0;
          text-transform: capitalize;
        }
      }

      &.openFromLeft {
        left: 0;
        transform-origin: top left;
      }

      &.openFromRight {
        right: 0;
        transform-origin: top right;
      }
    }

    .cabinClassPickerDropdown {
      background-color: $neutral-white;
      border-radius: 16px;
      box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
      display: flex;
      flex-direction: column;
      opacity: 0;
      overflow: hidden;
      padding: 8px 0;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: top right;
      transform: scale(0);
      transition: opacity 100ms ease-out, transform 150ms ease-out;
      z-index: 2;

      .cabinClass {
        align-items: center;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        height: 48px;
        line-height: 24px;
        padding: 0 24px;
        white-space: nowrap;

        &.selected {
          color: $green-500;
        }

        &:hover {
          background-color: $neutral-200;
        }
      }

      &:dir(rtl) {
        left: 0;
        right: unset;
        transform-origin: top left;
      }
    }

    .paymentTypePickerDropdown {
      background-color: $neutral-white;
      border-radius: 16px;
      box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
      display: flex;
      flex-direction: column;
      gap: 16px 0;
      opacity: 0;
      overflow: hidden;
      padding: 24px 24px 18px;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: top right;
      transform: scale(0);
      transition: opacity 100ms ease-out, transform 150ms ease-out;
      white-space: normal;
      width: 400px;
      z-index: 2;

      .message {
        color: $neutral-600;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .paymentMethods {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 12px;

        .paymentMethod {
          align-items: center;
          cursor: pointer;
          display: flex;
          gap: 0 8px;
          width: calc(50% - 8px);

          .checkbox {
            --checkbox-background-color: #{$green-400};
            --checkbox-size: 24px;
            border-radius: 2px;
            flex-shrink: 0;
          }

          .name {
            @include ellipsis;
          }
        }
      }

      .showMore {
        color: $green-400;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin: 2px 0;
      }

      .applyContainer {
        border-top: 1px solid $neutral-300;
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;

        > button {
          background-color: transparent;
          border: none;
          color: $green-400;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
          outline: none;
          padding: 0;
          text-transform: capitalize;
        }
      }

      &:dir(rtl) {
        left: 0;
        right: unset;
        transform-origin: top left;
      }
    }
  }
}
