@import '@styles/components/Color.module.scss';
@import '@styles/components/Triangle.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: center;
  padding: 0 32px;

  > .content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    max-width: var(--header-max-width, 1136px);
    width: 100%;
    z-index: 4;

    .logoAndNavLinks {
      align-items: center;
      display: flex;
      gap: 0 40px;
      height: 100%;

      .logoLink {
        background-image: url('https://assets.wego.com/image/sprite/c_pad,f_auto,q_auto:best,h_80,w_200/v1642649139/roxana-wego-logo');
        background-repeat: no-repeat;
        background-size: 100px 81px;
        display: block;
        height: 40px;
        width: 100px;

        &.dark {
          background-position: 0px 0px;
        }

        &.white {
          background-position: 0px -41px;
        }
      }

      .productLinks {
        display: flex;
        height: 100%;
        gap: 0 2rem;
        white-space: nowrap;

        .productLink {
          align-items: center;
          color: $neutral-900;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          height: 100%;
          line-height: 1.5;
          position: relative;
          text-decoration: none;
          text-transform: capitalize;

          &.additionalLinks {
            cursor: pointer;
            display: flex;
            gap: 12px;
            margin-right: 0.5rem;
          }

          .businessTravelLabel {
            font-weight: 400;
          }
          .badgeNew {
            background-color: $orange-400;
            border-radius: 4px;
            color: $neutral-white;
            font-size: 12px;
            line-height: 1.25rem;
            padding: 0 0.375rem;
            text-transform: uppercase;
            width: 2.5rem;
          }

          .additionalLinksPopover {
            position: absolute;
            top: 72px;
            background-color: #fff;
            z-index: 9999;
            left: 0;
            min-width: 200px;
            padding: 0.5rem 0;
            border-radius: 0 0 0.25rem 0.25rem;
            box-shadow: 0 2px 8px 0 rgba(39, 36, 44, 0.32);

            .dropdownItem {
              font-weight: 400;
              height: 56px;
              padding-inline: 1rem;
              white-space: nowrap;
              &:hover {
                background-color: #f4f2f7;
              }
              strong {
                font-weight: 600;
              }
            }
          }

          .triangle {
            @include triangle(6px);
            color: $neutral-white;
          }

          &.active {
            color: $green-400;

            &::after {
              background-color: $green-400;
              bottom: 0;
              content: '';
              height: 4px;
              left: 0;
              position: absolute;
              width: 100%;
            }
          }
        }
      }
    }

    .siteConfigurationAndAuthentication {
      display: flex;
      position: relative;
      gap: 32px;
      @media only screen and (max-width: 1023px) {
        gap: 24px;
      }

      .authentication {
        align-items: center;
        background-clip: padding-box;
        background-color: transparent;
        border: 1px solid $neutral-white;
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        height: 32px;
        outline: none;
        padding: 9px 16px;
        position: relative;

        &.hide {
          display: none;
        }

        &:hover,
        &.expanded {
          background-color: rgba($color: $neutral-900, $alpha: 0.8);
        }

        &.expanded {
          .triangle {
            transform: scaleY(-1);
          }
        }

        .triangle {
          @include triangle(6px);
          color: $neutral-white;
          margin-left: 12px;
        }
      }

      .authentication {
        border-radius: 100px;
        border-width: 1px;
        color: $neutral-white;
        display: flex;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;

        .name {
          @include ellipsis;
          max-width: 200px;
          text-transform: capitalize;

          &.isEmail {
            text-transform: lowercase;
          }

          .shopCash {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: center;
          }

          .icon {
            fill: $neutral-white;
            flex-shrink: 0;
            height: 24px;
            width: 24px;
          }

          .displayBalance {
            color: $neutral-white;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }

      .bowLoggedIn {
        align-items: flex-end;
        color: $neutral-600;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
      }

      .bowLoggedInEmail {
        display: flex;
        gap: pxToRem(5);

        & > span {
          color: $neutral-900;
        }
      }

      .switchAccount > span {
        color: $green-400;
        cursor: pointer;
      }

      .dropdown {
        align-items: flex-start;
        background-color: $neutral-white;
        border-radius: 16px;
        box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
        cursor: default;
        display: flex;
        flex-direction: column;
        max-height: 80vh;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translateY(6px);

        &.authenticationOptions {
          align-items: stretch;
          overflow: hidden;
          padding: 16px 0;

          .authenticationOption {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 16px;
            font-weight: 400;
            gap: 16px;
            height: 56px;
            line-height: 24px;
            min-width: 280px;
            padding: 0 16px;
            text-transform: capitalize;

            .icon {
              fill: $neutral-600;
              flex-shrink: 0;
              height: 24px;
              width: 24px;
            }

            &:hover {
              background-color: $neutral-200;
            }
          }
        }

        .shopCashOptions {
          margin: 8px 0 0;

          & > div {
            align-items: center;
            display: flex;
            padding: 0 16px;
          }

          .shopCashUserName {
            font-weight: 600;
            height: 24px;
            margin-bottom: 8px;
            text-transform: capitalize;

            &.isEmail {
              text-transform: lowercase;
            }
          }

          .shopCashBalanceLabel {
            color: $neutral-600;
            font-size: 12px;
            font-weight: 400;
            height: 18px;
            line-height: 18px;
          }

          .shopCashBalance {
            align-items: baseline;
            gap: 4px;
            height: 24px;
            line-height: 24px;
            margin-bottom: 8px;

            .amount {
              font-weight: 600;
            }

            .convertedAmount {
              color: $neutral-600;
              font-size: 12px;
              text-transform: initial;

              .approxValue {
                color: $neutral-600;
                display: inline;
                font-size: 12px;
                font-weight: 400;
                margin: 0 4px;
              }
            }
          }

          .shopCashInfo {
            background-color: $neutral-200;
            cursor: pointer;
            display: flex;
            font-size: 12px;
            line-height: 24px;
            gap: 4px;
            height: 36px;
            margin: 16px 0 16px;
            text-decoration: underline;

            .icon {
              width: 20px;
              height: 20px;
              margin: 0 4px;
            }
          }
        }
      }

      > .register {
        background-color: $green-400;
        border: none;
        color: $neutral-white;
        cursor: pointer;
        outline: none;
        text-transform: uppercase;
        border-radius: 100px;
        padding: 0 16px;
        font-size: 15px;
        line-height: 24px;
        margin-left: 8px;
        white-space: nowrap;

        &:hover {
          background-color: $green-500;
        }
      }

      .userNavLinks {
        display: flex;
        align-items: center;
        gap: 32px;
        margin: 0px;

        .userNavLink {
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0;
          text-align: right;
          color: $neutral-white;
          white-space: nowrap;

          .linkIcon {
            display: none;
            width: 24px;
            height: 24px;
            fill: currentColor;
          }
        }

        @media only screen and (max-width: 1023px) {
          gap: 24px;

          .userNavLink {
            .linkText {
              display: none;
            }

            .linkIcon {
              display: block;
            }
          }
        }
      }
    }
  }

  &.whiteBackground {
    background-color: $neutral-white;

    > .content {
      .siteConfigurationAndAuthentication {
        .authentication {
          background-color: $neutral-white;
          border-color: $neutral-900;
          color: $neutral-900;

          &:hover,
          &.expanded {
            background-color: $neutral-200;
          }

          .triangle {
            color: $neutral-600;
          }
        }

        .authentication {
          .icon {
            fill: $neutral-900;
          }

          .displayBalance {
            color: $neutral-900;
          }
        }

        .userNavLinks {
          .userNavLink {
            color: $neutral-900;
          }
        }
      }

      .logoAndNavLinks {
        .productLink {
          .triangle {
            color: $neutral-600;
          }
        }
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    > .content {
      .logoAndNavLinks {
        .productLink {
          &.active {
            &::after {
              left: unset;
              right: 0;
            }
          }
          &.additionalLinks {
            margin-left: 0.5rem;
            margin-right: 0;
          }

          .additionalLinksPopover {
            left: inherit;
            right: 0;
          }
        }
      }

      .siteConfigurationAndAuthentication {
        .authentication {
          &.expanded {
            &::after {
              left: unset;
              right: 50%;
              transform: translateX(50%) translateY(2px);
            }
          }

          .triangle {
            margin-left: 0;
            margin-right: 12px;
          }
        }

        .authentication,
        .register {
          margin-left: 0;
          margin-right: 8px;
        }

        .dropdown {
          left: 0;
          right: unset;
          transform: translateY(7px);

          &.authenticationOptions {
            .authenticationOption {
              .icon {
                transform: scaleX(-1);
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    > .content {
      .siteConfigurationAndAuthentication {
        .authentication {
          .icon {
            display: block;
          }
        }
      }

      .logoAndNavLinks {
        gap: 0 16px;
      }
    }
  }
}

.overlay {
  background-color: rgba($color: $neutral-black, $alpha: 0.5);
  opacity: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
  width: 100%;

  &.open {
    height: 100vh;
    opacity: 1;
  }
}

.login,
.signUp,
.resetPassword,
.resendConfirmation,
.shopCashInfoModal {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}
