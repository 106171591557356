@import '@styles/components/Color.module.scss';
@import '@styles/components/Chevron.module.scss';

.section {
  margin-top: 48px;
  max-width: 1136px;
  width: 100%;

  .sectionTitle {
    align-items: center;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    height: 28px;
    justify-content: space-between;
    line-height: 28px;

    > a {
      align-items: center;
      color: $green-400;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      gap: 0 4px;
      line-height: 24px;
      text-decoration: none;

      .chevron {
        @include chevron(8px);
        border-color: $green-400;
      }
    }
  }

  .blogs {
    margin: 8px -8px 0px;
  }

  > nav {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    margin-top: 8px;

    > a {
      color: $neutral-900;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
      width: calc(25% - 16px);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
