@import '@styles/components/Color.module.scss';

.container {
  height: fit-content;
  max-height: 90vh;
  overflow-y: scroll;

  background: $neutral-white;
  padding: 24px;
  border-radius: 8px;
  max-width: 560px;
  font-weight: 400;
  font-size: 15px;

  .closeIcon {
    cursor: pointer;
    fill: $green-400;
    flex-shrink: 0;
    height: 18px;
    width: 18px;
    position: absolute;
    right: 22px;
    top: 22px;
  }

  p {
    margin-top: 0;
    margin-bottom: 24px;
    line-height: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  .subTitle {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .cashbackSteps {
    margin: 6px 0 0;
    display: flex;
    flex-direction: column;
  }

  .cashbackStep {
    display: flex;
    gap: 16px;
  }

  .cashbackStepText {
    padding: 12px 0 0;

    .subTitle {
      margin: 0;
    }

    p {
      line-height: 20px;
    }
  }

  .illustration {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }

  .arrowIcon {
    fill: $green-400;
    margin: 8px 37px;
  }

  &:dir(rtl) {
    direction: rtl;

    &.container {
      .closeIcon {
        right: auto;
        left: 22px;
      }
    }
  }
}
