@import './Color.module.scss';
@import './NewInputStyle.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 4px;
  box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
  color: $neutral-900;
  overflow: hidden;
  width: 392px;

  .header {
    align-items: center;
    background-color: $neutral-200;
    border-bottom: 1px solid $neutral-300;
    display: flex;
    flex-direction: column;
    height: 96px;
    justify-content: center;
    padding: 10px;
    position: relative;

    .icon {
      cursor: pointer;
      fill: $neutral-600;
      height: 18px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 18px;
    }

    .title {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;

    .title {
      align-self: center;
      font-size: 20px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .message {
      color: $neutral-600;
      font-size: 14px;
      line-height: 21px;
    }

    .input {
      @include new-input-style;
      margin: 4px 0;
    }

    > button {
      align-items: center;
      background-color: $green-400;
      border: none;
      color: $neutral-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      justify-content: center;
      margin-top: 48px;
      outline: none;
      padding: 0;
      text-transform: capitalize;
      width: 100%;

      .spinner {
        --spinner-color: #{$neutral-100};
        --spinner-background-color: #{$neutral-300};
      }

      &:hover {
        background-color: $green-500;
      }

      &:disabled {
        background-color: $neutral-400;
      }
    }
  }

  &.rtl {
    direction: rtl;

    .header {
      .icon {
        left: 10px;
        right: unset;
      }
    }
  }
}
