@import './Color.module.scss';

@mixin wave($is-rtl: false) {
  position: relative;
  background-color: rgba($color: $neutral-black, $alpha: 0.11);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);

    @if $is-rtl {
      animation: rtlWave 1.6s linear 0.5s infinite;
    } @else {
      animation: wave 1.6s linear 0.5s infinite;
    }
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes rtlWave {
  0% {
    transform: translateX(100%);
  }

  60% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
