.container {
  max-width: 1136px;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 40px;
}

.description {
  flex-shrink: 0;
  width: 25%;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

.message {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.logos {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
  width: 100%;

  .logo {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: 25%;

    > img {
      height: 32px;
      object-fit: contain;
      max-width: 130px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .description {
    width: 35%;
  }
}
