@import './Color.module.scss';

.container {
  --price-symbol-font-size: inherit;
  --price-symbol-font-weight: 400;
  --strikethrough-color: #{$red-300};
  color: $green-400;
  direction: ltr;
  font-size: 20px;
  font-weight: 700;
  position: relative;

  &.strikethrough {
    &::after {
      content: ' ';
      position: absolute;
      background: var(--strikethrough-color);
      height: 1px;
      left: -2%;
      right: -2%;
      top: 50%;
    }
  }

  .symbol {
    font-size: var(--price-symbol-font-size);
    font-weight: var(--price-symbol-font-weight);
  }

  .amount {
    margin: 0 0 0 2px;
    white-space: nowrap;
  }

  .withRightMargin {
    margin: 0 2px 0 0;
  }
}
