@import '@styles/components/Color.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';
@import '@styles/components/Triangle.module.scss';

.container {
  display: flex;
  flex-direction: column;

  .minusSign {
    &::before {
      content: '\FF0D';
    }
  }

  .plusSign {
    &::before {
      content: '\FF0B';
    }
  }
  .rooms {
    max-height: 538px;
    overflow: auto;
    .room {
      padding: 16px 24px;

      .roomNumberAndRemoveButton {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .roomNumber {
          color: $neutral-900;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-transform: capitalize;
        }

        > button {
          background-color: transparent;
          border: none;
          color: $red-500;
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          outline: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .guests {
        align-items: center;
        display: flex;
        margin-top: 18px;

        .type {
          align-items: center;
          display: flex;
          margin-right: 32px;

          .icon {
            @include search-form-icons;
            flex-shrink: 0;
            margin-right: 8px;
          }

          .label {
            color: $neutral-900;
            font-size: 16px;
            line-height: 24px;
          }

          .requirement {
            color: $neutral-600;
            font-size: 12px;
            line-height: 18px;
          }
        }

        .stepperContainer {
          align-items: center;
          display: flex;

          > button {
            background-color: $green-400;
            border-radius: 4px;
            border: none;
            color: $neutral-white;
            cursor: pointer;
            height: 22px;
            outline: none;
            padding: 0;
            width: 22px;

            &.minusSign,
            &.plusSign {
              &::before {
                align-items: center;
                color: $neutral-white;
                display: flex;
                font-size: 16px;
                font-weight: 600;
                height: 22px;
                justify-content: center;
                width: 22px;
              }
            }

            &:hover {
              background-color: $green-500;
            }

            &:disabled {
              background-color: $neutral-400;
            }
          }

          .counter {
            color: $neutral-900;
            font-size: 16px;
            line-height: 24px;
            margin: 0 8px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.withRightMarging {
            margin-right: 40px;
          }
        }
      }

      .ageOfChildrenLabel {
        color: $neutral-900;
        font-size: 16px;
        line-height: 24px;
        margin-top: 28px;
      }

      .childrenAges {
        display: flex;
        flex-wrap: wrap;

        .childrenAge {
          height: 48px;
          margin: 8px 8px 0 0;
          position: relative;
          width: 68px;

          .customSelect {
            align-items: center;
            border-radius: 4px;
            border: 1px solid $neutral-300;
            cursor: pointer;
            display: flex;
            height: 100%;
            justify-content: space-between;
            left: 0;
            padding: 0 12px;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;

            .triangle {
              @include triangle(6px);
              color: $neutral-900;
            }
          }

          > select {
            border: none;
            cursor: pointer;
            height: 100%;
            margin: 0;
            opacity: 0;
            outline: none;
            width: 100%;

            &:hover {
              & + .customSelect {
                border-color: $neutral-500;
              }
            }
          }
        }
      }

      .childAgePresetDescription {
        color: $neutral-600;
        font-size: 12px;
        line-height: 20px;
        margin-top: 8px;
      }

      & + .room {
        border-top: 1px solid $neutral-300;
      }
    }
  }

  .addRoomAndApply {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-top: 1px solid $neutral-300;

    > button {
      align-items: center;
      background-color: $neutral-white;
      border-radius: 4px;
      border: none;
      color: $green-400;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      height: 48px;
      line-height: 20px;
      outline: none;
      padding: 0 16px;
      text-transform: capitalize;

      .plusSign {
        margin-right: 8px;

        &::before {
          align-items: center;
          border-radius: 50%;
          border: 2px solid $green-400;
          color: $green-400;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          height: 22px;
          justify-content: center;
          width: 22px;
        }
      }

      &:hover {
        background-color: $neutral-200;
      }

      &:disabled {
        background-color: $neutral-white;
        color: $neutral-400;

        .icon {
          border-color: $neutral-400;
          color: $neutral-400;
        }
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .room {
      .guests {
        .type {
          margin-left: 32px;
          margin-right: 0;

          .icon {
            margin-left: 8px;
            margin-right: 0;
          }
        }

        .stepperContainer {
          &.withRightMarging {
            margin-left: 40px;
            margin-right: 0;
          }
        }
      }

      .childrenAges {
        .childrenAge {
          margin: 8px 0 0 8px;

          .customSelect {
            left: unset;
            right: 0;
          }
        }
      }
    }

    .addRoomAndApply {
      > button {
        .plusSign {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
}
