@import '../../styles/components/Color.module';

.button {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.05s ease-in-out;

  &.pill {
    border-radius: 9999px;
  }

  &.lg {
    height: 48px;
    font-size: 16px;
    padding: 12px 20px;
    font-weight: 500;
  }

  &.md {
    height: 36px;
    font-size: 14px;
    padding: 8px 16px;
  }

  &.sm {
    height: 28px;
    font-size: 14px;
    padding: 2px 16px;
  }

  &.solid {
    color: white;

    &.green {
      background-color: $green-400;

      &:hover {
        background-color: $green-500;
      }

      &.light {
        color: $green-400;
        background-color: $green-100;
      }
    }

    &.orange {
      background-color: $orange-400;

      &:hover {
        background-color: $search-btn-hover-color;
      }

      &.light {
        color: $orange-400;
        background-color: $orange-100;
      }
    }

    &.red {
      background-color: $red-400;

      &:hover {
        background-color: $red-500;
      }

      &.light {
        color: $red-400;
        background-color: $red-100;
      }
    }

    &.grey {
      background-color: $neutral-300;
      color: $neutral-900;
      &:hover {
        background-color: $neutral-400;
      }

      &.light {
        background-color: $neutral-100;
        color: $neutral-900;
      }
    }

    &.disabled {
      &,
      &:hover {
        background-color: $light-grey-color;
        cursor: not-allowed;
        color: $neutral-400;
      }
    }
  }

  &.outline {
    background-color: transparent;

    &.green {
      border: 1px solid $green-400;
      color: $green-400;
    }

    &.orange {
      border: 1px solid $orange-400;
      color: $orange-400;
    }

    &.grey {
      border: 1px solid $neutral-300;
      color: $neutral-900;
    }

    &.red {
      border: 1px solid $red-400;
      color: $red-400;
    }

    &.disabled {
      &,
      &:hover {
        background-color: $light-grey-color;
        color: $neutral-400;
        border: unset;
        cursor: not-allowed;
      }
    }

    &.thick {
      border-width: 2px;
    }

    &.thin {
      border-width: 1px;
    }
  }

  &.ghost {
    background-color: transparent;

    &.disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  .content {
    margin: 0 7px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}
