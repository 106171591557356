@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin multi-line-ellipsis($line-number) {
  display: -webkit-box;
  -webkit-line-clamp: $line-number;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
