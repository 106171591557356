@import './Color.module.scss';
@import './Ellipsis.module.scss';

.container {
  --input-selector-focus-left: -16px;
  --input-selector-focus-right: unset;
  --input-selector-focus-width: 125%;
  --input-selector-left: 0;
  --input-selector-right: unset;
  --input-selector-transform-origin-rtl: top right;
  --input-selector-transform-origin: top left;
  --input-selector-transition-rtl: right 150ms ease-out, padding 150ms ease-out, top 150ms ease-out,
    width 150ms ease-out;
  --input-selector-transition: left 150ms ease-out, padding 150ms ease-out, top 150ms ease-out,
    width 150ms ease-out;
  background-color: $neutral-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  left: var(--input-selector-left);
  padding: 0;
  position: absolute;
  right: var(--input-selector-right);
  top: 0;
  transform-origin: var(--input-selector-transform-origin);
  transition: var(--input-selector-transition);
  width: 100%;

  .inputContainer {
    align-items: center;
    background-color: $neutral-white;
    border-radius: 8px;
    display: flex;
    height: 64px;
    overflow: hidden;
    position: relative;

    > label {
      color: $neutral-600;
      font-size: 16px;
      left: 0;
      pointer-events: none;
      position: absolute;
      text-transform: capitalize;
      top: 0;
      transform-origin: top left;
      transform: translate(16px, 20px) scale(1);
      transition: transform 150ms ease-out;
    }

    > input {
      @include ellipsis;
      background: none;
      border-radius: 8px;
      border: solid 1px $neutral-300;
      color: $neutral-900;
      font-size: 16px;
      font-weight: 600;
      height: 100%;
      outline: none;
      padding: 30px 40px 14px 16px;
      width: 100%;

      &:not([value='']),
      &:focus {
        & + label {
          transform: translate(16px, 12px) scale(0.75);
        }
      }
    }

    > button {
      align-items: center;
      background-color: $neutral-600;
      border-radius: 100%;
      border: none;
      cursor: pointer;
      display: flex;
      height: 22px;
      justify-content: center;
      outline: none;
      position: absolute;
      right: 16px;
      width: 22px;

      .icon {
        fill: $neutral-white;
        flex-shrink: 0;
        height: 18px;
        width: 18px;
      }

      &:hover,
      &:focus {
        background-color: $neutral-black;
      }
    }

    .box {
      background-color: $neutral-white;
      border-radius: 50%;
      border: solid 1px $neutral-300;
      height: 40px;
      position: absolute;
      right: -24px;
      width: 40px;
    }

    &.flightInbound {
      > label {
        transform: translate(32px, 20px) scale(1);
      }

      > input {
        padding: 30px 40px 14px 32px;

        &:not([value='']),
        &:focus {
          & + label {
            transform: translate(32px, 12px) scale(0.75);
          }
        }
      }

      .box {
        left: -24px;
        right: unset;
      }
    }

    &:hover {
      > input {
        border-color: $neutral-500;
      }

      .box {
        border-color: $neutral-500;
      }
    }
  }

  &.focus {
    border-radius: 16px;
    box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
    left: var(--input-selector-focus-left);
    padding: 16px;
    right: var(--input-selector-focus-right);
    top: -16px;
    width: var(--input-selector-focus-width);
    z-index: 2;

    .inputContainer {
      > input {
        border-color: $green-400;
      }

      .box {
        display: none;
      }
    }
  }

  &.validated {
    &.error {
      .inputContainer {
        > input {
          border-color: $red-500;
        }

        .box {
          border-color: $red-500;
        }
      }
    }
  }

  .children {
    margin: 8px -16px 0;
    max-height: 312px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &:dir(rtl) {
    direction: rtl;
    left: var(--input-selector-right);
    right: var(--input-selector-left);
    transform-origin: var(--input-selector-transform-origin-rtl);
    transition: var(--input-selector-transition-rtl);

    .inputContainer {
      > label {
        left: unset;
        right: 0;
        transform-origin: top right;
        transform: translate(-16px, 20px) scale(1);
      }

      > input {
        padding: 30px 16px 14px 40px;

        &:not([value='']),
        &:focus {
          & + label {
            transform: translate(-16px, 12px) scale(0.75);
          }
        }
      }

      > button {
        left: 16px;
        right: unset;
      }

      .box {
        left: -24px;
        right: unset;
      }

      &.flightInbound {
        > label {
          transform: translate(-32px, 20px) scale(1);
        }

        > input {
          padding: 30px 32px 14px 40px;

          &:not([value='']),
          &:focus {
            & + label {
              transform: translate(-32px, 12px) scale(0.75);
            }
          }
        }

        .box {
          left: unset;
          right: -24px;
        }
      }
    }

    &.focus {
      left: var(--input-selector-focus-right);
      right: var(--input-selector-focus-left);
    }
  }
}
