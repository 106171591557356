@import './Chevron.module.scss';
@import './Color.module.scss';

.container {
  align-items: center;
  display: flex;
  position: relative;
  --right-chevron-pos: -20px;

  .scrollPane {
    overflow: hidden;
    // overflow-x: auto;

    > div {
      display: flex;
      transition: transform 150ms ease-out;
      margin: 0 -1px 0 0;

      &::after {
        content: '';
        padding: 0 1px 0 0;
      }

      > * {
        flex-shrink: 0;
      }
    }
  }

  > button {
    align-items: center;
    background-color: $neutral-white;
    border-radius: 100%;
    border: none;
    box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    outline: none;
    padding: 0;
    position: absolute;
    width: 40px;

    .chevron {
      @include chevron(8px);
      border-color: $green-400;
    }

    &.left {
      left: -20px;
    }

    &.right {
      right: var(--right-chevron-pos);
    }

    &:disabled {
      display: none;
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .scrollPane {
      > div {
        margin: 0 0 0 -1px;

        &::after {
          padding: 0 0 0 1px;
        }
      }
    }

    > button {
      &.left {
        left: unset;
        right: -20px;
        transform: rotate(180deg);
      }

      &.right {
        left: -20px;
        right: unset;
        transform: rotate(180deg);
      }
    }
  }
}
