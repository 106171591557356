.container {
  margin-bottom: 1rem;
  display: flex;
  font-size: 1rem;
}

.avatar {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #188920;
  border-radius: 50%;
  color: #188920;
  margin-right: 1rem;

  &:dir(rtl) {
    margin-right: inherit;
    margin-left: 1rem;
  }
}

.info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: calc(100% - 4rem);
}

.name {
  color: #1d1d1d;
}

.email {
  color: #767676;
  margin-top: 0.25rem;
  font-size: 0.75rem;
}
