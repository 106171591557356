@import '@styles/components/PropositionIcons.module.scss';
@import '@styles/components/Color.module.scss';

.propositions {
  align-items: flex-start;
  display: flex;
  gap: 0 16px;
  justify-content: center;
  margin-top: 40px;
  max-width: 1136px;
  width: 100%;

  .proposition {
    display: flex;
    flex-direction: column;
    width: 272px;

    .propositionIcons {
      @include proposition-icons(100px);
      flex-shrink: 0;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-top: 8px;
      min-height: 24px;
    }

    .message {
      color: $neutral-600;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 4px;
      min-height: 24px;
    }

    .bowOnlyContainer {
      padding: 10px 10px 7px 10px;
      background: $green-100;
      border-radius: 8px;
      width: fit-content;
      color: $green-500;
    }
  }

  .itemsCenter {
    align-items: center;
  }

  .textCenter {
    text-align: center;
  }
}
