@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';
@import '@styles/components/SearchFormIcons.module.scss';

.container {
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  padding: 24px;

  > .label {
    font-size: 16px;
    line-height: 24px;
  }

  .selectors {
    display: flex;
    gap: 16px 8px;
    margin-top: 16px;

    .placePickerContainer {
      height: 64px;
      position: relative;
      width: 100%;

      .inputSelector {
        --input-selector-focus-width: 528px;
      }

      .category {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0 24px;

        &.with8TopMargin {
          margin-top: 8px;
        }

        &.with16TopMargin {
          margin-top: 16px;
        }
      }

      .place {
        align-items: center;
        display: flex;
        gap: 0 8px;
        height: 48px;
        padding: 0 28px;

        .icon {
          @include search-form-icons;
          flex-shrink: 0;
        }

        .labelAndHotelCount {
          align-items: center;
          display: flex;
          gap: 0 8px;
          justify-content: space-between;
          width: calc(100% - 32px);

          .label {
            @include ellipsis;
            font-size: 16px;
            line-height: 24px;
          }

          .hotelCount {
            color: $neutral-600;
            flex-shrink: 0;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
          }
        }

        &.with8TopMargin {
          margin-top: 8px;
        }

        &.selected,
        &.active,
        &:hover {
          background-color: $green-100;

          .icon {
            &.districtGrey {
              background-position: -25px -50px; // Is background-position of districtGreen
            }

            &.hotelGrey {
              background-position: -25px -75px; // Is background-position of hotelGreen
            }

            &.cityGrey {
              background-position: -50px -100px; // Is background-position of cityGreen
            }

            &.regionGrey {
              background-position: 0px -125px; // Is background-position of regionGreen
            }
          }

          .labelAndHotelCount {
            .label,
            .hotelCount {
              color: $green-500;
            }
          }
        }
      }

      .popularCities {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 24px 0;

        .popularCity {
          align-items: center;
          display: flex;
          height: 44px;
          padding: 0 8px;
          width: 25%;

          > div {
            @include ellipsis;
            font-size: 16px;
            line-height: 24px;
          }

          &:hover {
            background-color: $green-100;

            > div {
              color: $green-500;
            }
          }
        }
      }
    }

    .datePickerContainer {
      flex-shrink: 0;
      height: 64px;
      position: relative;
      width: 422px;

      .hotelDateSelector {
        --hotel-date-selector-focus-width: 632px;
      }
    }

    .guestRoomContainer {
      flex-shrink: 0;
      height: 64px;
      position: relative;
      width: 228px;
    }
  }

  .filtersAndSearchButton {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .filters {
      display: flex;
      gap: 0 24px;

      .filter {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 0 8px;

        .checkbox {
          --checkbox-background-color: #{$green-400};
          --checkbox-size: 24px;
          border-radius: 2px;
          flex-shrink: 0;
        }
      }
    }

    > button {
      align-items: center;
      background-color: $green-400;
      border-radius: 100px;
      border: none;
      color: $neutral-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      height: 48px;
      line-height: 24px;
      outline: none;
      padding: 0 40px;

      &:hover {
        background-color: $green-500;
      }
    }
  }

  .hotelComparisonProviders {
    align-items: center;
    background-color: $neutral-200;
    border-radius: 0 0 8px 8px;
    display: flex;
    height: 56px;
    margin: 24px -24px -24px;
    padding: 0 16px;

    .label {
      font-size: 16px;
      line-height: 24px;
    }

    .hotelComparisionProvider {
      align-items: center;
      cursor: pointer;
      display: flex;

      .checkbox {
        --checkbox-background-color: #{$green-400};
        --checkbox-size: 24px;
        border-radius: 2px;
        flex-shrink: 0;
        margin: 0 8px;
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;
  }

  @media only screen and (max-width: 1023px) {
    .selectors {
      flex-wrap: wrap;

      .placePickerContainer {
        .inputSelector {
          --input-selector-focus-width: calc(100% + 32px);
        }
      }

      .datePickerContainer {
        width: 65%;

        .hotelDateSelector {
          --hotel-date-selector-focus-width: 616px;
        }
      }

      .guestRoomContainer {
        width: calc(35% - 8px);
      }
    }
  }
}
