@import './Color.module.scss';

.container {
  display: flex;
  flex-direction: column;

  .month {
    align-items: center;
    color: $neutral-900;
    display: flex;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    line-height: 20px;
  }

  .dayLabels {
    align-items: center;
    display: flex;

    > div {
      color: $neutral-600;
      font-size: 14px;
      line-height: 20px;
      padding: 5px 0;
      text-align: center;
      width: 100%;

      &:nth-child(n + 6) {
        color: $neutral-900;
      }
    }
  }

  .days {
    display: flex;
    flex-wrap: wrap;

    > div {
      display: flex;
      justify-content: center;
      margin: 6px 0;
      width: calc(100% / 7);

      .date {
        align-items: center;
        border-radius: 4px;
        color: $neutral-900;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        line-height: 24px;
        width: 32px;

        &.publicHoliday {
          color: $red-500;
          font-weight: 500;

          &::after {
            content: '*';
            position: relative;
            top: -2px;
            margin-left: 1px;
          }
        }

        &.today {
          font-weight: 600;
        }

        &.selectable {
          cursor: pointer;

          &:hover,
          &.active {
            background-color: $green-100;
            color: $green-500;
          }
        }

        &.disabled {
          color: $neutral-400;
        }
      }

      &.fromDate,
      &.toDate {
        position: relative;

        .date {
          background-color: $green-400;
          color: $neutral-white;
        }

        &.hasToDate {
          &::before {
            content: '';
            background-color: $green-200;
            height: 100%;
            position: absolute;
            width: 50%;
            z-index: -1;
          }
        }
      }

      &.fromDate {
        &.hasToDate {
          &::before {
            right: 0;
          }
        }
      }

      &.toDate {
        &.hasToDate {
          &::before {
            left: 0;
          }
        }
      }

      &.inRange {
        background-color: $green-200;

        .date {
          color: $green-500;
          font-weight: 600;
        }
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .days {
      > div {
        .date {
          &.publicHoliday {
            &::after {
              content: '*';
              margin-right: 1px;
              margin-left: 0;
            }
          }
        }

        &.fromDate {
          &.hasToDate {
            &::before {
              left: 0;
              right: unset;
            }
          }
        }

        &.toDate {
          &.hasToDate {
            &::before {
              left: unset;
              right: 0;
            }
          }
        }
      }
    }
  }
}
