@mixin search-form-icons {
  background-image: url('https://assets.wego.com/image/sprite/c_pad,f_auto,q_auto:low,fl_lossy,w_48,h_48/v1665159998/roxana-search-form');
  background-repeat: no-repeat;
  background-size: 99px 149px;
  height: 24px;
  width: 24px;

  &.adultGrey {
    background-position: -75px 0px;
  }

  &.airportGreen {
    background-position: 0px -25px;
  }

  &.airportGrey {
    background-position: -25px -25px;
  }

  &.attractionGreen {
    background-position: -50px -25px;
  }

  &.attractionGrey {
    background-position: -75px -25px;
  }

  &.childGrey {
    background-position: 0px -50px;
  }

  &.districtGreen {
    background-position: -25px -50px;
  }

  &.districtGrey {
    background-position: -50px -50px;
  }

  &.flightsGreen {
    background-position: -75px -50px;
  }

  &.flightsWhite {
    background-position: 0px -75px;
  }

  &.hotelGreen {
    background-position: -25px -75px;
  }

  &.hotelGrey {
    background-position: -50px -75px;
  }

  &.hotelsGreen {
    background-position: -75px -75px;
  }

  &.hotelsWhite {
    background-position: 0px -100px;
  }

  &.infantGrey {
    background-position: -25px -100px;
  }

  &.cityGreen {
    background-position: -50px -100px;
  }

  &.cityGrey {
    background-position: -75px -100px;
  }

  &.regionGreen {
    background-position: 0px -125px;
  }

  &.regionGrey {
    background-position: -25px -125px;
  }

  &.trainsGreen {
    background-position: -50px -125px;
  }

  &.trainsWhite {
    background-position: -75px -125px;
  }
}
