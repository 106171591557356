@import './Color.module.scss';
@import './NewInputStyle.module.scss';
@import './OAuthProviderLogo.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 4px;
  box-shadow: 0 0 24px 2px rgba($color: $neutral-black, $alpha: 0.08);
  color: $neutral-900;
  display: flex;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    width: 392px;

    .oAuthProvider {
      align-items: center;
      border-radius: 30px;
      border: none;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      height: 44px;
      outline: none;
      padding: 0 12px;

      &.facebook {
        background-color: $facebook-blue-color;
        color: $neutral-white;
      }

      &.google {
        background-color: $neutral-white;
        color: $neutral-600;
        padding: 0;
      }

      .oAuthProviderLogo {
        @include o-auth-provider-logo;
        margin-right: 24px;
      }

      & + .oAuthProvider {
        margin-top: 8px;
      }
    }

    .strikethrough {
      border-bottom: 1px solid $neutral-300;
      height: 12px;
      margin: 16px 0 20px 0;
      text-align: center;

      &::after {
        background-color: $neutral-white;
        content: attr(data-text);
        font-size: 14px;
        margin-bottom: 20px;
        padding: 0 6px;
      }
    }

    .input {
      @include new-input-style;
    }

    .optInReceivePromotionsFromWego {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;

      .checkbox {
        --checkbox-background-color: #{$green-400};
        --checkbox-size: 24px;
        border-radius: 2px;
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 8px;
      }
    }

    .termsAndConditions {
      font-size: 14px;

      > a {
        color: $green-400;
      }
    }

    .link {
      align-self: flex-start;
      color: $green-400;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;

      & + .link {
        margin-top: 16px;
      }
    }

    .signUp {
      align-items: center;
      background-color: $green-400;
      border-radius: 4px;
      border: none;
      color: $neutral-white;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      justify-content: center;
      margin-top: 48px;
      outline: none;
      padding: 0;
      text-transform: capitalize;
      width: 100%;

      .spinner {
        --spinner-color: #{$neutral-100};
        --spinner-background-color: #{$neutral-300};
      }

      &:hover {
        background-color: $green-500;
      }

      &:disabled {
        background-color: $neutral-400;
      }
    }

    .successMessage {
      line-height: 1.5;
      text-align: center;

      > span {
        color: $green-400;
      }
    }

    &.successful {
      justify-content: center;
    }
  }

  .rightHeader {
    background-color: $neutral-200;
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    position: relative;
    width: 304px;

    > .icon {
      cursor: pointer;
      fill: $neutral-600;
      height: 18px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 18px;
    }

    .title {
      align-self: center;
      font-size: 20px;
      line-height: 30px;
    }

    .subtitle {
      align-self: center;
      color: $neutral-600;
      font-size: 14px;
      line-height: 1.5;
      margin-top: 40px;

      > span {
        color: $green-400;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .propositions {
      margin-top: 40px;
      border-top: 1px solid $neutral-300;

      .proposition {
        display: flex;
        margin-top: 40px;

        .iconContainer {
          align-items: center;
          background-color: $neutral-white;
          border-radius: 50%;
          display: flex;
          flex-shrink: 0;
          height: 48px;
          justify-content: center;
          margin-right: 16px;
          width: 48px;

          .icon {
            fill: $green-400;
            height: 24px;
            width: 24px;
          }
        }

        .messageContainer {
          font-size: 14px;
          line-height: 1.5;

          .message {
            font-weight: 300;
            margin-top: 4px;
            color: $neutral-700;
          }
        }
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .content {
      .oAuthProvider {
        .oAuthProviderLogo {
          margin-left: 24px;
          margin-right: 0;
        }
      }

      .optInReceivePromotionsFromWego {
        .checkbox {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }

    .rightHeader {
      > .icon {
        left: 10px;
        right: unset;
      }

      .propositions {
        .proposition {
          .iconContainer {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
