@mixin chevron($size) {
  border-style: solid;
  border-width: 2px 2px 0 0;
  height: $size;
  width: $size;

  &.top {
    transform: rotate(-45deg);
  }

  &.right {
    transform: rotate(45deg);
  }

  &.bottom {
    transform: rotate(135deg);
  }

  &.left {
    transform: rotate(-135deg);
  }
}
