@import '@styles/components/Color.module.scss';
@import '@styles/functions/pxToRem.module.scss';
@import '@styles/mixins/Screen.module.scss';

.container {
  color: $neutral-900;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.flightSearch2 {
    max-width: pxToRem(704);

    @include md {
      max-width: pxToRem(944);
    }

    @include lg {
      max-width: pxToRem(1136);
    }
  }

  .legSearchContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .legSearch {
      background-color: $neutral-200;
      border-radius: 8px;
      display: flex;
      align-items: center;
      height: 64px;
      gap: 8px;
      padding: 10px 12px;
      flex-grow: 1;
      flex-shrink: 1;

      &.withMaxWidth {
        @media screen and (max-width: 1279px) {
          flex-basis: 32%;
          max-width: 33%;
        }
      }

      .index {
        flex-shrink: 0;
        width: 28px;
        height: 28px;
        border: 1px solid $neutral-300;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $neutral-white;
        color: $neutral-600;
      }

      .code {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
      }

      .date {
        font-size: 14px;
        line-height: 20px;
        color: $neutral-600;
      }
    }
  }

  .actionBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .legsAndCabinClass {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .legs {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #27242c;
      }

      .cabinClassAndPassengerCount {
        display: flex;
        text-transform: capitalize;
        color: $neutral-900;
        gap: 0 16px;
        font-size: 16px;
        line-height: 24px;

        .divider {
          background-color: $neutral-600;
          width: 1px;
        }
      }
    }

    .editButton {
      width: 144px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $green-400;
      border-radius: 8px;
      color: $neutral-white;
      outline: 0;
      border: 0;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &:hover {
        background-color: $green-500;
      }
    }
  }
}
