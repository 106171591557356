@import '@styles/components/Chevron.module.scss';
@import '@styles/components/Color.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  position: relative;

  .chevronContainer {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    position: absolute;
    width: 40px;

    .chevron {
      @include chevron(8px);
      border-color: $neutral-900;

      &.disabled {
        border-color: $neutral-400;
      }
    }

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }

  .calendars {
    display: flex;
    justify-content: space-between;

    .calendar {
      margin-right: 12px;
      width: 50%;

      & + .calendar {
        margin-left: 12px;
        margin-right: 0;
      }
    }

    .fareCalendar {
      margin-right: pxToRem(12);

      & + .fareCalendar {
        margin-left: pxToRem(12);
        margin-right: 0;

        &:dir(rtl) {
          margin-left: 0;
          margin-right: pxToRem(12);
        }
      }

      &:dir(rtl) {
        margin-left: pxToRem(12);
        margin-right: 0;
      }
    }
  }

  .label {
    color: $neutral-600;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin: 10px 0 0 8px;
    text-transform: uppercase;
  }

  .publicHolidays {
    display: flex;
    flex-wrap: wrap;
    margin-left: 8px;

    .publicHoliday {
      align-items: center;
      background-color: $neutral-200;
      border-radius: 4px;
      color: $neutral-600;
      display: flex;
      font-size: 12px;
      height: 26px;
      line-height: 18px;
      margin: 8px 8px 0 0;
      padding: 0 8px;

      .date {
        color: $red-500;
        margin-right: 8px;
      }
    }
  }

  &.fareCalendarContainer {
    .chevronContainer {
      height: pxToRem(48);
    }

    .label {
      color: $neutral-900;
      line-height: pxToRem(16);
      text-transform: capitalize;
      margin-top: pxToRem(16);
      margin-left: 0;

      &:dir(rtl) {
        margin-top: pxToRem(16);
        margin-right: 0;
      }

      .asterisk {
        color: $neutral-900;
        position: relative;
        top: pxToRem(-2);
      }
    }

    .publicHolidays {
      margin-left: 0;
      .publicHoliday {
        font-size: pxToRem(14);

        .date {
          color: $neutral-900;
        }
      }

      &:dir(rtl) {
        margin-right: 0;
      }
    }
  }

  &.rtl {
    direction: rtl;

    .calendars {
      .calendar {
        margin-left: 12px;
        margin-right: 0;

        & + .calendar {
          margin-left: 0;
          margin-right: 12px;
        }
      }
    }

    .label {
      margin: 10px 8px 0 0;
    }

    .publicHolidays {
      margin-left: 0;
      margin-right: 8px;

      .publicHoliday {
        margin: 8px 0 0 8px;

        .date {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
}
