@import '../Color.module.scss';
@import '../Ellipsis.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  height: 384px;
  margin: 8px;
  overflow: hidden;
  text-decoration: none;
  width: 272px;

  .image {
    height: 184px;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 184px);
    padding: 16px 16px 12px;

    .name {
      @include multi-line-ellipsis(2);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .starAndDistrict {
      align-items: center;
      display: flex;
      height: 18px;
      margin-top: 4px;

      .star {
        fill: $orange-300;
        height: 16px;
        width: 16px;

        &:last-of-type {
          margin-right: 8px;
        }
      }

      .district {
        @include ellipsis;
        color: $neutral-600;
        font-size: 12px;
      }
    }

    .review {
      align-items: center;
      column-gap: 4px;
      display: flex;
      margin-top: 8px;

      .score {
        align-items: center;
        border-radius: 14px;
        color: $neutral-white;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        height: 24px;
        padding: 0 8px;

        &.excellent {
          background-color: $trust-you-green-color;
        }

        &.fair {
          background-color: $trust-you-yellow-color;
        }

        &.poor {
          background-color: $trust-you-red-color;
        }
      }

      .reviewCount {
        color: $neutral-600;
        font-size: 12px;
        line-height: 1.67;
      }
    }

    .rate {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;

      .priceType {
        color: $neutral-600;
        font-size: 12px;
        line-height: 18px;
      }

      .price {
        --price-symbol-font-size: 12px;
        color: $neutral-900;
        font-size: 20px;
        font-weight: 600;
      }

      .noRate {
        color: $green-400;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;

    .content {
      .starAndDistrict {
        .star {
          &:last-of-type {
            margin-left: 8px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
