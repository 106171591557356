@import '@styles/components/Color.module.scss';

.section {
  margin-top: -184px;
  max-width: 1136px;
  width: 100%;
  margin-top: 48px;
}

.sectionTitle {
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  height: 28px;
  justify-content: space-between;
  line-height: 28px;

  > a {
    align-items: center;
    color: $green-400;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    gap: 0 4px;
    line-height: 24px;
    text-decoration: none;
  }
}

.popularHotels {
  margin: 8px -8px 0px;
}
