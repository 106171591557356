@mixin o-auth-provider-logo {
  background-image: url('https://assets.wego.com/image/sprite/c_pad,q_auto:low,fl_lossy,w_36,h_36/v1588768373/oauth-providers');
  background-repeat: repeat;
  background-size: 18px 36px;
  height: 18px;
  width: 18px;

  &.facebook {
    background-position: 0px 0px;
  }

  &.google {
    background-position: 0px -18px;
  }
}
