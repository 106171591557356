@import '@styles/components/Color.module.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;

  .title {
    color: $neutral-700;
    font-size: 18px;
    margin: 16px 0;
  }

  .message {
    color: $neutral-500;
    font-size: 15px;
    margin-bottom: 16px;
    text-align: center;
  }

  > button {
    background-color: $neutral-white;
    border-radius: 20px;
    border: 1px solid $neutral-300;
    color: $neutral-900;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    outline: none;
    padding: 8px 24px;
    text-transform: capitalize;
  }
}
