@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  height: 240px;
  margin: 8px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 176px;

  .image {
    height: 100%;
  }

  .cityName {
    @include ellipsis;
    background: linear-gradient(to top, $neutral-900, 40%, transparent);
    bottom: 0;
    color: $neutral-white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 8px;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
