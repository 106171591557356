@import '@styles/components/Color.module.scss';

.container {
  --dialog-background-color: #{$neutral-white};
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  visibility: hidden;
  z-index: 1300;

  &.open {
    visibility: visible;

    .overlay {
      opacity: 1;
      visibility: visible;
    }
    .wrapper {
      opacity: 1;
    }
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
  visibility: hidden;
}

.wrapper {
  border-radius: 16px;
  padding: 32px;
  background-color: var(--dialog-background-color);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  width: 600px;
  opacity: 0;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1200;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 10px;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 5px;
}
