@import '@styles/components/Color.module.scss';
@import '@styles/functions/pxToRem.module.scss';

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &.flightSearch2 {
    .searchTypes {
      .searchTypeButton {
        border-radius: pxToRem(8);
        font-size: pxToRem(14);
        line-height: pxToRem(18);

        &.selected {
          border: 1px solid $green-400;
          color: $neutral-900;
        }
      }
    }
  }

  .searchTypes {
    align-items: center;
    display: flex;
    gap: 0 8px;

    .searchTypeButton {
      align-items: center;
      background-color: $neutral-200;
      border-radius: 100px;
      border: none;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      height: 40px;
      line-height: 24px;
      outline: none;
      padding: 0 16px;

      &.selected {
        background-color: $green-100;
        color: $green-500;
      }
    }
  }
}
