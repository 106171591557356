@import '@styles/components/Color.module.scss';
@import '@styles/components/Ellipsis.module.scss';

.container {
  background-color: $neutral-white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
  color: $neutral-900;
  display: flex;
  flex-direction: column;
  height: 344px;
  margin: 8px;
  overflow: hidden;
  text-decoration: none;
  width: 368px;

  .image {
    height: 216px;
    object-fit: cover;
    width: 100%;
  }

  .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: calc(100% - 216px);
    padding: 16px;

    .title {
      @include multi-line-ellipsis(3);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .wegoIconContainer {
      align-items: flex-end;
      color: $neutral-600;
      display: flex;
      flex-grow: 1;
      font-size: 12px;
      font-weight: 400;
      gap: 0 8px;
      line-height: 16px;

      > img {
        height: 16px;
        width: 16px;
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;
  }
}
