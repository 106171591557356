@font-face {
  font-family: 'Geeza Pro';
  font-display: swap;
  src: local('Geeza Pro'), url(./GeezaProRegular.ttf) format('truetype');
  // Arabic unicode range
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF;
}

@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import './components/Color.module.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

button,
body,
input,
textarea {
  font-family: 'Inter', 'Geeza Pro', sans-serif;
  // text-shadow: -3px 0 1px cyan, 3px 0 1px red;
}

body {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  margin: 0;
  overflow: auto;
  // user-select: none;
  width: 100%;
  // text-shadow: -3px 0 1px cyan, 3px 0 1px red;

  :global(#app) {
    height: 100%;
    min-height: 100%;
    position: relative;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    background-clip: padding-box;
    background-color: rgba($color: $neutral-black, $alpha: 0.15);
    border-radius: 6px;
    border: 2px solid rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-button {
    display: none;
    height: 0;
    width: 0;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

:global(.horizontal) {
  display: flex;
  flex-direction: row;
}

:global(.vertical) {
  display: flex;
  flex-direction: column;
}

:global(.center-v) {
  display: flex;
  justify-content: center;
}

:global(.center-h) {
  display: flex;
  align-items: center;
}

// This to remove chrome autofill style on input https://wego.slack.com/archives/C04E53S74E8/p1733108821845079
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out !important;
}
