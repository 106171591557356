@import '@styles/components/Color.module.scss';

.container {
  color: $neutral-900;

  .travelThemes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 8px;

    .travelTheme {
      align-items: center;
      background-color: $neutral-200;
      border-radius: 100px;
      cursor: pointer;
      display: flex;
      height: 40px;
      padding: 0 16px;

      &.active {
        background-color: $green-100;
        color: $green-400;
      }
    }
  }

  .travelThemeCities {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px;
    margin-top: 16px;

    .travelThemeCity {
      border-radius: 8px;
      box-shadow: 0px 0px 8px 2px rgba($color: $neutral-black, $alpha: 0.1);
      color: $neutral-900;
      display: flex;
      height: 148px;
      overflow: hidden;
      text-decoration: none;
      width: 368px;

      .image {
        flex-shrink: 0;
        height: 100%;
        width: 148px;
      }

      .content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 16px;

        .cityName {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .countryName {
          color: $neutral-600;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-top: 4px;
        }

        .roundTripLabel {
          align-items: flex-end;
          color: $neutral-600;
          display: flex;
          flex-grow: 1;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }

        .price {
          --price-symbol-font-size: 12px;
          color: $neutral-900;
          font-size: 20px;
          font-weight: 600;
        }

        .noFare {
          align-items: flex-end;
          color: $green-400;
          display: flex;
          flex-grow: 1;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  &:dir(rtl) {
    direction: rtl;
  }

  @media only screen and (max-width: 768px) {
    .travelThemeCities {
      .travelThemeCity {
        width: 344px;
      }
    }
  }
}
