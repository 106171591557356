@import './Color.module.scss';

@mixin new-input-style {
  --input-border-bottom: solid 1px #{$neutral-300};
  --input-border-radius: 0;
  --input-border: none;
  --input-focus-placeholder-transform: translate(0, 14px) scale(0.75);
  --input-font-size: 14px;
  --input-height: 52px;
  --input-icon-height: 20px;
  --input-icon-right: 8px;
  --input-icon-width: 20px;
  --input-message-font-size: 12px;
  --input-message-margin: 4px 0;
  --input-padding: 26px 32px 8px 0;
  --input-placeholder-font-size: 14px;
  --input-placeholder-transform: translate(0, 18px) scale(1);
  --input-rtl-focus-placeholder-transform: translate(0, 14px) scale(0.75);
  --input-rtl-icon-left: 8px;
  --input-rtl-padding: 26px 0 8px 32px;
  --input-rtl-placeholder-transform: translate(0, 18px) scale(1);
}
