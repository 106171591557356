@import '@styles/components/Color.module.scss';

.contentError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4rem;

  .content {
    max-width: 25rem;
  }

  .icon {
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 24px;
    color: $neutral-900;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    color: $neutral-600;
    margin-bottom: 1.5rem;
  }
}
